.selected {
    padding: 16px;
    border-radius: 4px;
    background-color: var(--invalid-name);
    overflow: hidden;
    margin-bottom: 16px;
}
// ---------------------  重置 Start ---------------------
.reset__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.reset {
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: #2c36de;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;

    span:nth-child(1) {
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
    }
    span:nth-child(2) {
        font-size: 16px;
        margin-left: 4px;
    }
    &:hover {
        background-color: #2c36de;
        color: var(--invalid-name);
    }
}
.reset__wrapper .reset {
    margin-right: 12px;
}
.reset__wrapper .reset:last-child {
    margin-right: 0;
}
.reset__wrapper .reset.active {
    background-color: #2c36de;
    color: var(--invalid-name);
}
.reset__wrapper .reset.active:hover {
}
// ---------------------  重置 End ---------------------
// ---------------------  已选筛选项 Start ---------------------
.selected {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.selected__value {
    padding: 4px 12px 4px 16px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f8;
    box-sizing: border-box;
    height: 30px;
    &:hover {
        .icon,
        .text {
            color: #2c36de;
        }
    }
    .text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
    }
    .icon {
        color: #999899;
        font-size: 20px;
        line-height: 22px;
        margin-left: 4px;
        cursor: pointer;
    }
}
// ---------------------  已选筛选项 End ---------------------

.header {
    padding: 16px 16px 12px;
    background-color: var(--invalid-name);
    border-radius: 4px 4px 0 0;
    display: flex;
    justify-content: space-between;
}
.header__left {
    display: flex;
    flex: 1;
    gap: 24px;
}
// ---------------------  排序 Start ---------------------
.sort {
    flex-shrink: 0;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 30px;
    letter-spacing: normal;
    color: #262626;
    cursor: pointer;
}
.sort.active {
    font-weight: 500;
    font-family: PingFangSC-Medium;
    line-height: 30px;
    color: #2c36de;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        display: inline-block;
        bottom: -12px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #2c36de;
    }
}
// ---------------------  排序 End ---------------------

.header__right {
    flex-shrink: 0;

    display: flex;
    gap: 10px;
}
