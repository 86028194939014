.drawer {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999;
    box-sizing: border-box;
    width: 130px;
    height: 100vh;
    background-color: #fff;
    box-shadow: 10px 0 36px 0 rgba(0, 0, 0, 0.14);
    transition: all 0.2s;

    &.drawerOpen {
        width: 360px;
    }
}

.expand {
    height: 30px;
}

@media screen and (max-width: 768px) {
    .drawer {
        display: none;
    }
}
