.analyst {
    padding: 24px 24px 40px;
    border-radius: 4px;
    background-color: var(--pc-base-background);
    position: relative;
}

.analystTabs {
    padding: 25px 0;
    box-sizing: border-box;
    background-color: var(--pc-base-background);
    position: relative;
    border-bottom: 1px solid #f2f2f4;

    :global {
        .swiper-button-prev,
        .swiper-button-next {
            top: 55%;
            background-color: var(--pc-base-background);
        }
    }
}

.analystList {
    width: 100%;
    overflow-y: auto;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 1px;
        background: #ebebeb;
    }

    &::-webkit-scrollbar-track {
        background: #f7f7f8;
    }
}

.botTip {
    margin-top: 24px;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.6px;
    color: #999;
    padding-top: 24px;
    border-top: 1px solid #f2f2f4;
}
