// ------------- 超出省略 Start ------------- //
.maxLine(@x) {
    display: -webkit-box; //特别显示模式
    overflow: hidden; //溢出内容隐藏
    white-space: pre-wrap;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    word-wrap: break-word;
    word-break: normal;
    overflow-wrap: anywhere;
    -webkit-line-clamp: @x; //行数
    line-clamp: @x;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
}
// ------------- 超出省略 End ------------- //
.tabsCon {
    min-height: 300px;
    // padding-top: 24px;
}
.wrapper__nav {
    // margin-top: 16px;
}
.wrapper__article {
    display: flex;
    flex-direction: row-reverse;
}
.wrapper__article.column__4,
.wrapper__article.column__3 {
    min-height: 1145px;
}

.tabsConBox {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    .wrapper__header__h5 {
        .wrapper__h2 {
            position: relative;
            z-index: 9;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            height: 36px;
            padding: 0 12px;
            padding-top: 12px;
            background-color: var(--pc-base-background);
            border-radius: 6px 6px 0 0;

            span {
                position: relative;
                z-index: 9;
                display: inline-block;
                color: #000;
                font-weight: 500;
                font-family: PingFangSC-Medium;
                font-size: 16px;
                line-height: 1.5;
                text-align: left;
                .maxLine(1);
            }
        }
    }
    .top_link {
        display: flex;
        height: 30px;
        align-items: center;
        justify-content: center;
        color: #999;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5;
        text-align: left;
        cursor: pointer;
        &:hover {
            color: #2c36de !important;
            &::after {
                color: #2c36de !important;
            }
        }
    }
    .top_link::after {
        margin-left: 6px;
        color: #999 !important;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-family: swiper-icons;
        font-variant: normal;
        font-variant: initial;
        line-height: 1;
        letter-spacing: 0;
        text-align: center;
        text-transform: none !important;
        content: 'next';
    }
}

.wrapper__header__h5 .tabs {
    height: 22px;
    padding: 8px 12px 0;
    background-color: var(--pc-base-background);
    :global {
        .swiper-initialized {
            height: 22px !important;
            padding: 0 !important;
            .swiper-slide {
                color: #262626;
                span,
                span span {
                    height: 22px !important;
                    margin-right: 24px;
                    color: #262626;
                    font-size: 14px !important;
                    line-height: 1.57;
                }
                ._active-seriesItem {
                    color: #2c36de !important;
                }
                ._active-seriesItem::after {
                    display: none;
                }
            }
        }
    }
}

// ----------------------- PC 头部 Start -----------------------
.wrapper__header {
    display: flex;
    box-sizing: border-box;
    padding: 24px 16px 8px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 6px 6px 0 0;
    .wrapper__header_line {
        display: flex;
        width: 100%;
    }
    .has_underline {
        box-sizing: border-box;
        border-bottom: 1px solid #e5e5e5;
    }
    .wrapper__title {
        position: relative;
        flex-shrink: 0;
        max-width: 50%;
        h2 {
            position: relative;
            z-index: 1;
            margin-right: 40px;
            color: #000;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: 24px;
            font-style: normal;
            font-stretch: normal;
            line-height: 30px;
            letter-spacing: normal;
            .maxLine(1);
        }
    }
    .top_link {
        flex-shrink: 0;
        color: #999;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: normal;
    }
}
.wrapper__header .tabs {
    flex: 1;
    box-sizing: border-box;
    // width: 716px;
    padding: 0 12px 0 0;
    overflow: hidden;
    background-color: var(--pc-base-background);
    :global {
        .swiper-button-next,
        .swiper-button-prev {
            display: none;
        }
        .swiper-initialized {
            padding: 0 !important;
            background-color: #fff;
            .swiper-slide {
                color: #262626;
                span,
                span span {
                    margin-right: 24px;
                    color: #262626;
                    font-size: 18px !important;
                    &:hover {
                        color: #2c36de !important;
                    }
                }
                ._active-seriesItem {
                    color: #2c36de !important;
                }
                ._active-seriesItem::after {
                    display: none;
                }
            }
        }
    }
}
// ----------------------- PC 头部 End -----------------------

@media screen and (max-width: 768px) {
    .wrapper__article {
        height: auto;
    }
    .wrapper__article.column__4,
    .wrapper__article.column__3 {
        min-height: auto;
    }
    .wrapper__nav {
        display: block;
        padding-top: 0 !important;
        margin-top: 12px;

        .tabsConBox {
            min-width: auto;
        }
    }
    .wrapper__header.barkly_container {
        background-color: #0c0d13;
    }
}

.wrapper__header.barkly_container {
    background-color: #0c0d13;
    padding-left: 0;
    padding-right: 0;
    .tabs {
        background-color: #0c0d13;
        :global {
            .swiper-initialized {
                background-color: #0c0d13;
                .swiper-slide ._active-seriesItem {
                    color: #fff !important;
                }
                .swiper-slide {
                    color: #262626;
                    span,
                    span span {
                        font-size: 20px !important;
                        color: rgba(255, 255, 255, 0.6);
                        &:hover {
                            color: #fff !important;
                        }
                    }
                }
            }
        }
    }
    .has_underline {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
}

.barkly_container {
    .wrapper__header__h5 {
        .wrapper__h2 {
            background-color: #0c0d13;
        }
        .tabs {
            background-color: #0c0d13;
            padding: 8px 0 0 0;
            :global {
                .swiper-initialized {
                    .swiper-slide {
                        color: #262626;
                        span {
                            margin: 0;
                        }
                        span span {
                            margin-right: 24px;
                            color: rgba(255, 255, 255, 0.6);
                        }
                        ._active-seriesItem {
                            color: #fff !important;
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
