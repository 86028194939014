.active_con {
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    z-index: 10;
    .top_box {
        z-index: 3;
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 24px 16px 8px 16px;
        .top_title {
            position: relative;
            font-size: 24px;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            z-index: 10;
            line-height: 1.25;
        }
    }
    .wrapper__more {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        cursor: pointer;
        color: #999;
        &:hover {
            color: #2c36de;
        }
        .text__more {
            font-weight: normal;
            font-size: 14px;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            display: flex;
            align-items: center;
            letter-spacing: normal;
        }
        .arrow__more {
            font-size: 18px;
            line-height: normal;
        }
    }
}
