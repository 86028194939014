.brokerSelect {
    width: 100%;

    :global {
        .ant-select-selector {
            height: 34px;
            border: 1px solid #d9d9de !important;
            box-shadow: none !important;
        }
    }

    .suffix {
        color: #666;
        font-size: 14px;
        transform: rotateZ(90deg);
        transform-origin: 50% 50%;
    }
    &:hover {
        :global {
            .ant-select-selector {
                border-color: #2c36de !important;
            }
        }
    }
}

.popupClass {
    width: 100% !important;
    padding: 0 !important;
    border: solid 1px #e5e5e5;
    border-radius: 4px;

    :global {
        .rc-virtual-list-scrollbar {
            width: 4px !important;
        }
    }
}

.swiperBox {
    height: 38px;
    padding: 0 12px;
    border-bottom: 1px solid #f2f2f4;
}

.tabsItem {
    position: relative;
    width: auto;
    color: #999;
    font-size: 12px;
    line-height: 38px;

    &:last-child {
        margin-right: 0 !important;
    }

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: transparent;
        content: '';
    }

    &.active {
        color: #333;
        font-size: 14px;

        &::after {
            background-color: var(--theme-color);
        }
    }
}

.selOption {
    width: 100%;
    height: 38px;
    border-radius: 0 !important;

    :global {
        .ant-select-item-option-content {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    .logo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        border: solid 1px #ebebeb;
        border-radius: 4px;
    }

    .img {
        background-color: #fff;
        border-radius: 4px;
    }

    .name {
        flex: 1;
        margin-left: 12px;
        overflow: hidden;
        color: #010b27;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
    }
}

.nullData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;

    .nullTxt {
        margin-top: 8px;
        color: #666;
        font-size: 12px;
    }

    :global {
        .ant-image {
            max-width: 100%;
        }
    }
}

.noMore {
    cursor: auto !important;

    .noMoreText {
        padding: 8px 0;
        color: var(--pc-base-f1-color);
        font-size: 12px;
        text-align: center;
    }
}
