@import '~@/styles/utils.less';

.swiperCenter {
    width: 100%;
}

.swiperContain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;

    :global {
        .swiper-pagination {
            bottom: 15px;
            height: 5px;
        }

        .swiper-button-disabled {
            cursor: pointer;
            opacity: 1;
            pointer-events: auto;
        }
    }
}

.swiperLeft {
    width: calc(50% - 8px);
    height: 100%;

    // 轮播图翻页整体样式
    .swiperBullet {
        // position: relative;
        // bottom: 12px;
        display: inline-block;
        width: 28px;
        height: 2px;
        margin-right: 4px;
        background: rgba(255, 255, 255, 0.4);
        border-radius: 2px;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }
    }

    //  轮播图翻页选中状态
    .swiperBulletActive {
        position: relative;
        // bottom: 12px;
        overflow: hidden;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: #fff;
            border-radius: 1px;
            animation: progressMove linear 5s 1 forwards;
            content: '';
        }
    }

    .swiperBtnNext,
    .swiperBtnPrev {
        top: 50%;
        width: 38px;
        height: 38px;
        object-fit: contain;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        opacity: 0;
        transition: opacity 0.8s;
        &::after {
            color: var(--pc-base-background);
            font-weight: 400;
            font-size: 12px;
        }
    }

    .swiperBtnNext {
        right: 16px;
    }

    .swiperBtnPrev {
        left: 16px;
    }

    .swiperSlideImg {
        transition: transform 0.8s;
    }

    .swiperCon {
        height: 100%;
        overflow: hidden;
        border-radius: 6px;

        :global {
            .swiper-pagination-horizontal {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 24px;
            }
        }
    }

    .swiperSlide {
        box-sizing: border-box;
    }

    .swiperSlideBox {
        position: relative;
        height: 100%;
        cursor: pointer;
    }

    .swiperSlideTitle {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 9;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(-180deg, hsla(0, 0%, 74.9%, 0), rgba(0, 0, 0, 0.5) 100%);
        transition: all 0.5s;

        .swiperSlideTitleText {
            .maxLine(2);

            padding: 0 24px;
            position: absolute;
            bottom: 42px;
            left: 0;
            box-sizing: border-box;
            width: 100%;
            color: var(--pc-base-background);
            font-weight: 500;
            font-family: PingFangSC-Medium;
            max-height: 72px;
            font-size: 24px;
            line-height: 1.5;
            transition: all 0.5s;
        }
    }

    .swiperSlideTitleOne {
        .swiperSlideTitleText {
            bottom: 24px;
        }
    }

    .recScrollTrackY {
        width: 0 !important;
        background: transparent !important;
    }

    .recScrollThumbY {
        width: 0 !important;
        background: transparent !important;
    }

    &:hover {
        .swiperSlideImg {
            transform: scale(1.12);
        }

        // .swiperSlideTitle,
        // .swiperSlideTitleOne {
        //     background-image: linear-gradient(-180deg, hsla(0, 0%, 74.9%, 0), rgba(0, 0, 0, 0.5) 100%);

        //     // .swiperSlideTitleText {
        //     //     max-height: none;
        //     //     max-height: 100%;
        //     //     overflow: visible;
        //     //     -webkit-line-clamp: unset; //行数
        //     //     line-clamp: unset;
        //     //     box-sizing: border-box;
        //     //     bottom: 0;
        //     // }
        // }

        // .swiperSlideTitle {
        // padding-top: 24px;
        // padding-bottom: 42px;
        // }

        .swiperSlideTitleOne {
            padding: 24px 0;
        }

        .swiperBtnNext,
        .swiperBtnPrev {
            background-color: #fff;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
            opacity: 1;
            &::after {
                color: #171617;
            }
        }
    }
}

.swiperRight {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, calc(50% - 8px));
    grid-template-rows: repeat(2, calc(50% - 8px));
    width: calc(50% - 8px);
    height: 100%;
    margin: 0;
    padding: 0;

    .swiperCon {
        height: 100%;
        background-color: #d9d9d9;
        border-radius: 6px;
    }

    .swiperSlideImg {
        transition: transform 0.5s;
    }
    & > li {
        width: 100%;
        height: 100%;

        .swiperSlideBox {
            position: relative;
            height: 100%;
            cursor: pointer;
        }

        .swiperSlide {
            box-sizing: border-box;
            height: 100%;
        }

        .swiperSlideTitle {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 9;
            display: flex;
            align-items: flex-end;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(-180deg, hsla(0, 0%, 74.9%, 0), rgba(0, 0, 0, 0.78) 97%);
            transition: all 0.5s;

            .swiperSlideTitleText {
                padding: 0 16px;
                position: absolute;
                bottom: 16px;
                left: 0;
                display: -webkit-box;
                box-sizing: border-box;
                width: 100%;
                max-height: 52px;
                color: var(--pc-base-background);
                font-weight: 500;
                font-family: PingFangSC-Medium;
                font-size: 18px;
                line-height: 1.44;
                transition: all 0.5s;
                .maxLine(2);
            }
        }

        :hover {
            .swiperSlideImg {
                transform: scale(1.12);
            }
            .swiperSlideTitle {
                background: rgba(0, 0, 0, 0.4);
                background-image: linear-gradient(-180deg, hsla(0, 0%, 74.9%, 0), rgba(0, 0, 0, 0.78) 97%);

                .swiperSlideTitleText {
                    .maxLine(4);

                    max-height: 104px;
                    bottom: 50%;
                    transform: translateY(50%);
                }
            }
        }
    }
}

.recScroll {
    width: 100%;
}

@keyframes progressMove {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

@media screen and (max-width: 998px) {
    .swiperSlideTitle {
        min-height: 60px;
        max-height: 108px;
        font-size: var(--pc-font-size-18);

        .swiperSlideTitleText {
            min-height: 28px;
            max-height: 70px;
            margin: 12px 16px 22px;
        }

        .swiperSlideTitleOne {
            margin: 12px;
            line-height: 150%;
        }
    }
}
