.textOverMin {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.expand {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: 10px 0 36px 0 rgba(0, 0, 0, 0.14);
}

.header {
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    padding: 16px 16px 16px 24px;
    position: relative;
    background-image: linear-gradient(to right, #f0f3ff 0%, #f8faff 100%);

    p {
        .textOverMin();
    }

    .to {
        color: #333;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 18px;
    }

    .tt {
        margin-top: 4px;
        margin-bottom: 0;
        color: #666;
        font-size: 12px;
    }
}

.openBtn {
    position: absolute;
    top: 16px;
    left: -16px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow:
        0 2px 4px -1px rgba(0, 0, 0, 0.12),
        0 4px 5px 0 rgba(0, 0, 0, 0.08),
        0 1px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    margin-right: 12px;
    cursor: pointer;
    color: #262626;

    .icon {
        font-size: 12px;
        line-height: 1;
    }
}

.headerText {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span:first-child {
        color: #333;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 18px;
    }

    .openBtn {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.contrast {
    padding: 0 0 40px;

    .contrastBtnBox {
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }

    .contrastBtn {
        min-width: 102px;
        height: 32px;
        color: var(--invalid-name);
        font-size: 14px;
        letter-spacing: 0.5px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;

        &.noData {
            color: var(--invalid-name);
            background: #adbaff;
            cursor: not-allowed;

            &:hover,
            &:focus {
                color: var(--invalid-name);
                background: #adbaff !important;
            }
        }
    }

    .contrastText {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 0 8px;
        letter-spacing: 0.5px;
    }

    .contrastTextHover {
        &:hover {
            background-color: var(--btn-hover-color);
        }
    }

    .clearBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
    }

    .clearBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        span {
            color: #999;
        }

        & > span:last-child {
            font-size: 12px;
            letter-spacing: 0.43px;
        }
    }

    .clearBtnHover {
        &:hover {
            span {
                color: var(--theme-color);
            }
        }
    }

    .clearIcon {
        margin-right: 4px;
        font-size: 16px;
        line-height: 1;
    }
}

.contrastList {
    & > li {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        border-bottom: solid 1px #f2f2f4;

        .closeIconBox {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            width: 40px;
            height: 100%;
        }

        .closeIcon {
            color: #c6c6c6;
            font-size: 16px;
            line-height: 1;
            cursor: pointer;
        }
    }
}

.brokerBtn {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-right: 56px;
    padding-left: 24px;
    cursor: pointer;

    &:hover {
        background-color: #f7f7f8;
    }

    &.mouseDown {
        background-color: #ebebeb;
    }

    .logo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        border: solid 1px #ebebeb;
        border-radius: 4px;
    }

    .img {
        background-color: #fff;
        border-radius: 4px;
    }

    .name {
        flex: 1;
        margin: 0 12px;
        overflow: hidden;
        color: #333;
        line-height: 1.57;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    .num {
        margin-left: auto;
        color: #fe3434;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 20px;
        font-family: D-DIN, sans-serif;
        line-height: 1;
        letter-spacing: 0.5px;
    }
}

.errorMsg {
    margin: 24px 0 0;
    padding: 0 24px;
    color: #fe3434;
    font-size: 12px;
    text-align: center;
    word-break: normal;
}

.selBroker {
    position: relative;
    margin: 24px 24px 0;

    .addBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 54px;
        height: 54px;
        margin: 0 auto;
        background-color: #f7f8fb;
        border: solid 1px #ebebeb;
        border-radius: 27px;
        cursor: pointer;
    }

    .addIcon {
        color: #979797;
        font-size: 13px;
        line-height: 1;
    }

    .tip {
        margin-top: 4px;
        color: #999;
        font-size: 14px;
        line-height: 1.57;
        text-align: center;
    }
}

.midTip {
    height: 60px;
    padding: 0 24px;
    color: #333;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    line-height: 60px;
    background-image: linear-gradient(to right, #f0f3ff 0%, #f8faff 100%);
}

.recBox {
    position: relative;
    flex: 1;
    box-sizing: border-box;
    width: 100%;

    &:hover {
        .recScrollTrackY {
            background: #f7f7f8 !important;
        }

        .recScrollThumbY {
            background: #ebebeb !important;
        }
    }
}

.recScrollTrackY {
    width: 4px !important;
    background: transparent !important;
    border-radius: 0 !important;
}

.recScrollThumbY {
    width: 4px !important;
    background: transparent !important;
}

.recList {
    display: flex;
    flex-direction: column;
    padding: 0 14px 40px 24px;

    li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
        height: 52px;
        border-bottom: 1px solid #f2f2f4;
        user-select: none;
    }

    .addBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        min-width: 54px;
        height: 22px;
        padding: 0 8px;
        background-color: #f0f3ff;
        border-radius: 4px;
        cursor: pointer;

        span {
            color: #2c36de;
        }

        & > span:first-child {
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: 8px;
        }

        & > span:last-child {
            position: relative;
            left: 2px;
            margin-left: 2px;
            font-size: 12px;
        }

        &.addBtnDis {
            background-color: rgba(235, 235, 235, 0.55);
            border-color: #ebebeb;
            cursor: not-allowed;

            span {
                color: #ccc;
            }
        }
    }
}

.recBroker {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: calc(100% - 86px);
    height: 100%;
    cursor: pointer;

    .logo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        border: solid 1px #ebebeb;
        border-radius: 4px;
    }

    .img {
        background-color: #fff;
        border-radius: 4px;
    }

    .name {
        flex: 1;
        margin: 0 12px;
        overflow: hidden;
        color: #333;
        line-height: 1.57;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
    }
}
