.keyWordBox {
    width: 1344px !important;
    background: #fff;
    padding: 26px 16px;
    border-radius: 6px;
}

.keyWords {
    // width: 100%;
    height: 40px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;

    :global {
        .swiper-button-disabled {
            opacity: 1;
            background: transparent;

            & > div,
            &::after {
                opacity: 0.35;
            }
        }
    }
}

.keyWordsHasBtn {
    padding: 0 26px 0 42px;
}

.keyWordItem {
    width: auto;
    line-height: 28px;
    display: inline-block;
    padding: 0 16px;
    cursor: pointer;
    white-space: nowrap;
    letter-spacing: 1.5px;

    a {
        line-height: 40px;
        font-size: var(--pc-font-size-18);
        color: var(--pc-base-f10-color);
    }

    &:hover {
        a {
            font-size: var(--pc-font-size-20);
            font-weight: 500;
            font-family: PingFangSC-Medium;
            color: var(--theme-color);
        }
    }

    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 45%;
        position: absolute;
        top: 54%;
        right: 0;
        transform: translateY(-50%) rotateZ(25deg);
        background-color: #000000;
    }

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;

        &::after {
            display: none;
        }
    }

    &.swiper-slide-active:first-child {
        padding-left: 42px;
    }

    &.swiper-slide-active:last-child {
        padding-right: 24px;
    }
}

.swiperBackPrev,
.swiperBackNext {
    position: absolute;
    top: 7px;
    z-index: 1;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: var(--pc-base-background);
    box-shadow:
        0px 2px 4px -1px rgba(0, 0, 0, 0.12),
        0px 4px 5px 0px rgba(0, 0, 0, 0.08),
        0px 1px 10px 0px rgba(0, 0, 0, 0.05);
}

.swiperBackPrev {
    left: 2px;
}

.swiperBackNext {
    right: 2px;
}

.swiperBtnPrev,
.swiperBtnNext {
    width: 70px;
    height: 36px;
    position: absolute;
    top: 60%;
    z-index: 9;

    &::after {
        font-size: 8px;
        color: var(--pc-normal-color);
        z-index: 2;
    }
}

.swiperBtnPrev {
    left: 0;
    background: linear-gradient(to left, transparent 0%, #fff 50%, #fff 100%);

    &::after {
        margin-right: 44px;
        margin-top: 2px;
    }
}

.swiperBtnNext {
    right: 0;
    background: linear-gradient(to right, transparent 0%, #fff 50%, #fff 100%);

    &::after {
        margin-left: 44px;
        margin-top: 2px;
    }
}

@media screen and (max-width: 998px) {
    .keyWordItem {
        a {
            font-size: var(--pc-font-size-16);
        }
    }
}

@media screen and (max-width: 628px) {
    .keyWordBox {
        // display: none;
    }
}
