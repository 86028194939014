.tabsCon {
    padding-top: 24px;
    min-height: 300px;
}

.tabsConBox {
    & > h2 {
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        span:first-child {
            display: inline-block;
            font-size: 24px;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            line-height: 32px;
            letter-spacing: 0.75px;
            color: #010b27;
        }

        // a:last-child {
        //   display: inline-block;
        //   width: 24px;
        //   height: 24px;
        //   margin-left: 24px;
        //   background-size: 100% 100%;
        //   margin-top: 2px;
        //   background-repeat: no-repeat;
        //   background-position: center center;
        // }
    }
}

@media screen and (max-width: 628px) {
    .tabsConBox,
    .tabsCon {
        padding-top: 0 !important;
    }
}

@media screen and (max-width: 415px) {
    .tabsConBox {
        & > h2 {
            margin-bottom: 8px;
            padding: 0 24px;
            justify-content: space-between;

            span:first-child {
                display: inline-block;
                line-height: 24px;
                font-size: 18px;
                letter-spacing: 0.56px;
            }

            a:last-child {
                width: 18px;
                height: 18px;
            }
        }
    }
}
