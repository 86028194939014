.brokerBox {
    width: 100%;
    display: flex;
    justify-content: center;

    .suffix {
        font-size: 18px;
        color: #262f2d;
    }

    .form {
        width: 86%;
        max-width: 280px;

        :global {
            .atn-form-item {
                width: 100%;
            }
        }
    }
}

.brokerSelect {
    width: 100%;
    height: 48px;

    :global {
        .ant-select-selector {
            height: 100% !important;
            border-radius: 4px !important;
            padding: 0 16px !important;
            border: solid 1px rgba(26, 35, 61, 0.1);

            input {
                height: 48px !important;
                font-size: 16px;
            }

            .ant-select-selection-item,
            .ant-select-selection-placeholder {
                line-height: 46px !important;
            }

            .ant-select-selection-item {

                display: flex;
                align-items: center;

                .logo {
                    width: 28px;
                    height: 28px;
                    margin-right: 8px;
                    border-radius: 14px;
                    border: 1px solid #ebebeb;
                    overflow: hidden;
                    box-sizing: border-box;
                    position: relative;
                }

                .text {
                    line-height: 1.5;
                    letter-spacing: 0.5px;
                    color: #262f2d;
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: pre;
                }
            }
        }
    }
}

.selOption {

    :global {
        .ant-select-item-option-content {
            display: flex;
            align-items: center;
        }
    }

    .logo {
        width: 28px;
        height: 28px;
        margin-right: 8px;
        border-radius: 14px;
        border: 1px solid #ebebeb;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;
    }

    .text {
        line-height: 1.5;
        letter-spacing: 0.5px;
        color: #262f2d;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
    }

    .business__name {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-align: right;
        color: #00c6a4;
    }
}

.selectDropdown {
    .searchInput {
        width: 100%;
        max-width: 280px;
        height: 40px;
        border: none;
        padding: 0 16px;
        border-radius: 0;
        border: none;
        color: #999999;
        caret-color: #00e0b9;

        input {
            height: 16px;
            margin: 12px 0;
        }

        .prefix {
            color: #999999;
        }

        :global {
            .ant-input-prefix {
                margin-right: 8px;
            }

            .ant-input-affix-wrapper {
                padding: 0;
            }
        }
    }

    .divider {
        margin: 0;
        border-color: #ebebeb;
    }

    :global {
        .rc-virtual-list {

            .ant-select-item {
                height: 48px;
                line-height: 1.5;
                letter-spacing: 0.5px;
                color: #262f2d;
                padding: 8px 16px;
                box-sizing: border-box;
                border-radius: 0;

                &.ant-select-item-option-selected {
                    background-color: #ebebeb;
                }
            }
        }
    }
}

.nullData {
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .nullTxt {
        margin-top: 8px;
        font-size: 14px;
        color: #666666;
    }

    :global {
        .ant-image {
            max-width: 100%;
        }
    }
}