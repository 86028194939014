.all__wrapper {
    display: flex;
    gap: 16px;
}
.left__wrapper {
    flex-shrink: 0;
    width: 332px;
    border-radius: 4px;
    overflow: hidden;
    position: sticky;
    border-top: 16px solid #f5f5fa;
    height: min-content;
    background-color: var(--invalid-name);
}

.right__wrapper {
    flex: 1;
    border-radius: 4px;
    width: calc(100% - 332px - 16px);
}
