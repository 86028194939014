@import '~@/styles/utils.less';

.wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.billboard_top {
    display: flex;
    align-items: center;
    padding-left: 16px;
}

.list {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    margin: 0 40px;
}

.list_item {
    width: auto !important;
    padding: 0 12px;
    color: #262626;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
    line-height: 30px;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    &:hover,
    &.active {
        color: var(--theme-color);
    }

    &.active {
        // font-size: 20px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
    }
}

.more {
    display: flex;
    align-items: center;
    color: #999;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        color: var(--theme-color);
    }

    .arrow {
        width: 14px;
        height: 14px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px;
    }
}

.details_list_box {
    position: relative;
}

.details_list {
    padding: 0;

    li {
        position: relative;

        padding: 24px 16px;
        cursor: pointer;

        &:hover {
            background-color: #f7f7f8;
        }

        &.no_bottom::after {
            display: none;
        }
    }
}

.null_data {
    flex: 1;
}
