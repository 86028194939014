.listTitle {
    height: 40px;
    margin-bottom: 24px;
    color: #010b27;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    line-height: 40px;
}

.listItem {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 278px;
    height: 289px;
    margin-bottom: 24px;
    overflow: hidden;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 21, 41, 0.06);
    cursor: pointer;
    transition: all 0.2s;
    user-select: none;

    .coverUrl {
        position: relative;
        width: 100%;
        height: 164px;
    }

    img {
        max-width: 100%;
    }

    &:hover {
        transform: translateY(-5px);
    }
}

.listItemContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
}

.title {
    display: -webkit-box; //特别显示模式
    box-sizing: border-box;
    overflow: hidden; //溢出内容隐藏
    color: #262b2e;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    line-height: 27px;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    word-break: normal;
    -webkit-line-clamp: 2; //行数
    line-clamp: 2;
    -webkit-box-orient: vertical; //盒子中内容竖直排列

    &:hover {
        color: var(--pc-primary-color);
    }
}

.botMsg {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .time {
        color: #666666;
        font-size: 12px;
    }

    .btns {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 60px;
        height: 28px;
        color: #999999;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        line-height: 12px;
        background: #ffffff;
        border: 1px solid #e9e9e9;
        border-radius: 14px;

        &.before {
            width: 70px;
            color: #ff3141;
        }

        &.progressing {
            color: #00c800;
        }

        &.end {
            color: #999999;
        }
    }
}

.status {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 6px 10px;
    color: #0ec370;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    border: solid 1px;
    border-radius: 14px;

    i {
        font-size: 16px;
    }
}

@media screen and (max-width: 415px) {
    .listTitle {
        display: none;
    }

    .list {
        box-sizing: border-box;
        width: 100%;
        padding: 0 24px;
        background-color: #ffffff;
    }

    .listItem {
        flex-direction: row;
        width: 100%;
        max-width: none;
        height: auto;
        margin-bottom: 0;
        padding: 16px 0;
        border-bottom: 1px solid #f7f8fb;
        border-radius: 0;
        box-shadow: none;
        transition: none;

        &:hover {
            transform: translateY(0);
        }

        .coverUrl {
            width: 104px;
            height: 66px;
            overflow: hidden;
            border-radius: 5px;
        }
    }

    .listItemContent {
        width: calc(100% - 112px);
        margin-left: 8px;
        padding: 0;
    }

    .title {
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        line-height: 22px;
    }

    .botMsg {
        align-items: flex-end;

        .btns {
            width: 50px;
            height: 22px;
            font-size: 10px;
            line-height: 10px;
        }
    }
}
