.main {
    display: flex;
    flex-direction: column;
}
// ------------------- 排序 Start -------------------
.sort {
    display: flex;
    gap: 24px;
    overflow: auto;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: var(--invalid-name);
    padding: 16px 12px 0;
    &::-webkit-scrollbar {
        display: none;
    }
}
.sort__item {
    flex-shrink: 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
}
.sort__item.active {
    color: #2c36de;
    font-weight: 500;
    font-family: PingFangSC-Medium;
}
// ------------------- 排序 End -------------------

.list__item {
    padding: 12px 12px 0;
    background-color: var(--invalid-name);
    & > div {
        padding-bottom: 12px;
        border-bottom: 1px solid #e5e5e5;
    }
}
.list__item:nth-last-child(2) {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    /* 设置样式 */
    & > div {
        border-bottom: none;
    }
}
