.wrapper {
    display: flex;
    width: 100%;

    &.lastWrapper {
        & > li {
            border-bottom: 1px solid #ebebeb;
        }

        & > li > ul {
            width: 100%;
        }
    }

    & > li {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 200px;
        padding: 14px 16px;
        color: #262f2d;
        font-size: 12px;
        word-break: break-word;
        background-color: #fff;
        border-top: 1px solid #ebebeb;
        border-right: 1px solid #ebebeb;

        &:first-child {
            width: 100px;
            color: #666666;
            background-color: #fbfbfb;
            border-left: 1px solid #ebebeb;
            position: sticky;
            left: 0;
            z-index: 1;
        }

        .icon {
            height: 18px;
            font-size: 18px;
            line-height: 1;
            color: #262f2d;
            display: flex;
            align-items: center;
        }
    }

    .hrefHover {
        color: var(--theme-color);
        cursor: pointer;
    }
}

.textList {
    color: #262f2d;
    line-height: 1.5;
    letter-spacing: 0.5px;

    p {
        margin: 0;
    }
}
