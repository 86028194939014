@import url('~@/styles/utils.less');

.wrapper {
    background-color: #fff;
    border-radius: 4px;
}
// ------------------ 头部 Start ------------------
.header {
    z-index: 3;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 12px 12px 0 12px;
}

.wrapper__more {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    cursor: pointer;
    color: #999;
    &:hover {
        color: #2c36de;
    }
    .text__more {
        font-weight: normal;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        display: flex;
        align-items: center;
        letter-spacing: normal;
    }
    .arrow__more {
        font-size: 16px;
        line-height: normal;
    }
}

.tab_wrapper {
    display: flex;
    padding: 12px 12px 0;
    gap: 24px;
    .tab_item {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: normal;
        text-align: left;
        color: var(--color-text-primary);
    }
    .tab_item.active {
        color: var(--theme-color);
    }
}
// ------------------ 头部 End ------------------
// ------------------ 轮播图 Start ------------------
.swiper_list {
    padding: 12px 12px 0;
    :global {
        .swiper-pagination {
            bottom: 14px;
            .swiper-pagination-bullet {
                width: 28px;
                height: 2px;
                border-radius: 0;
            }
        }
    }
}
.list_item {
    display: flex;
    flex-direction: column;
}
.topic_header,
.ugc_header {
    display: flex;
    padding-bottom: 12px;
    gap: 8px;
    border-bottom: var(--pc-primary-border-1);
}
.name,
.title {
    .maxLine(1);
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-primary);
}
.img_con {
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.topic_header {
    .img_con {
        width: 74px;
        height: 74px;
        border-radius: 4px;
    }
    .text_content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .introduction {
        .maxLine(2);
        font-size: 12px;
        line-height: 18px;
        color: #666;
        height: 36px;
    }
}
.ugc_header {
    align-items: center;
    .img_con {
        width: 62px;
        height: 62px;
        border-radius: 62px;
    }
}
// ------------------ 轮播图 End ------------------
// ------------------ 稿件列表 Start ------------------
.article_list {
    padding: 12px 0 50px;
    height: 460px;
    box-sizing: border-box;
    display: flex;
    gap: 12px;
    flex-direction: column;
}
.article_list_item {
    display: flex;
    gap: 12px;
}
.article_list_title {
    .maxLine(2);
    font-size: 14px;
    line-height: 22px;
    height: 44px;
    color: var(--color-text-primary);
    flex: 1;
}
.article_list_cover {
    flex-shrink: 0;
    width: 75px;
    height: 50px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.article_list_item.one {
    flex-direction: column;
    .article_list_cover {
        width: 100%;
        height: 218px;
    }
}
// ------------------ 稿件列表 End ------------------
