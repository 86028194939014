@import url('~@/styles/utils.less');

.wrapper {
    height: 815px;
    background-color: var(--invalid-name);
}
// ----------------------- 头部 Start -----------------------
.tool_top {
    z-index: 3;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 24px 16px 16px 24px;
}
.wrapper__more {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    color: #999;
    cursor: pointer;
    &:hover {
        color: #2c36de;
    }
    .text__more {
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
    }
    .arrow__more {
        font-size: 18px;
        line-height: normal;
    }
}
// ----------------------- 头部 End -----------------------
.tool_wrapper {
    display: flex;
    padding: 0 16px 16px;
}
.tabs {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 16px;
    width: 290px;
    height: 100%;
    .top_icon {
        display: none;
    }

    span {
        .maxLine(1);
        height: 58px;
        color: #000;
        font-size: 18px;
        line-height: 58px;
        text-align: center;
        cursor: pointer;
    }
}

.tabs .active {
    position: relative;
    .top_icon {
        .bg__default();
        display: block;
        background-image: url('/img/visual/broker/left-icon.webp');
        position: absolute;
        display: block;
        width: 9px;
        height: 26px;
        top: calc(50% - 13px);
        right: -1px;
        background-color: var(--invalid-name);
    }

    color: #fff;
    span {
        color: #2c36de;
    }
}

.tool {
    flex: 1;
    width: 992px !important;
    box-sizing: border-box;
    height: 713px;
    overflow: auto;
    border: solid 1px #e5e5e5;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    scrollbar-width: 8px;
    &::-webkit-scrollbar {
        width: 8px;
    }
    a {
        display: none;
    }
}
