// ------------- 超出省略 Start ------------- //
.maxLine(@x) {
    display: -webkit-box; //特别显示模式
    overflow: hidden; //溢出内容隐藏
    white-space: pre-wrap;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    word-wrap: break-word;
    word-break: normal;
    overflow-wrap: anywhere;
    -webkit-line-clamp: @x; //行数
    line-clamp: @x;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
}
// ------------- 超出省略 End ------------- //
// ------------- 默认背景图设置 Start ------------- //
.bg__default {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}
// ------------- 默认背景图设置 End ------------- //
// ------------- 加粗字体 Start ------------- //
.font__bold {
    font-weight: 500;
    font-family: 'PingFangSC-Medium';
}
// ------------- 加粗字体 End ------------- //
