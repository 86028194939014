.filter {
    border-radius: 6px 6px 0 0;
    padding: 24px 24px 16px;
    background-color: var(--invalid-name);
}
.filter__item {
    display: flex;
    align-items: center;
    gap: 12px;
    // 状态筛选项
    &:nth-child(1) {
        padding-bottom: 18px;
    }
    // 区域筛选项
    &:nth-child(2) {
        padding-bottom: 24px;
        border-bottom: 1px solid #e5e5e5;
        justify-content: space-between;
        .filter__value {
            display: flex;
            align-items: center;
            gap: 12px;
            flex: 1;
        }
    }
    // 筛选条件
    &:nth-child(3) {
        padding-top: 16px;
    }
    // 活动筛选条件
    &:nth-child(4) {
        padding-top: 12px;
    }
    // 区域筛选条件
    &:nth-child(5) {
        padding-top: 10px;
    }
}
.label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #999;
    flex-shrink: 0;
}
.value {
    flex-shrink: 0;
    padding: 4px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.value.active {
    background-color: #2c36de;
    color: var(--invalid-name);
}

// ---------------------  重置 Start ---------------------
.reset__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.reset {
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: #2c36de;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;

    span:nth-child(1) {
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
    }
    span:nth-child(2) {
        font-size: 16px;
        margin-left: 4px;
    }
    &:hover {
        background-color: #2c36de;
        color: var(--invalid-name);
    }
}
.reset__wrapper .reset {
    margin-right: 12px;
}
.reset__wrapper .reset:last-child {
    margin-right: 0;
}
.reset__wrapper .reset.active {
    background-color: #2c36de;
    color: var(--invalid-name);
}
.reset__wrapper .reset.active:hover {
}
// ---------------------  重置 End ---------------------
// ---------------------  已选筛选项 Start ---------------------
.selected {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
.selected .label {
    margin-right: 0;
}
.selected__value {
    padding: 4px 12px 4px 16px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f8;
    box-sizing: border-box;
    height: 30px;
    &:hover {
        .icon,
        .text {
            color: #2c36de;
        }
    }
    .text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
        padding-right: 4px;
    }
    .icon {
        color: #999899;
        font-size: 20px;
        line-height: 22px;
        cursor: pointer;
    }
}
// ---------------------  已选筛选项 End ---------------------
