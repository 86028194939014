@import '~@/styles/utils.less';

.checkbox {
    padding: 16px;
}
.header {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    em {
        position: absolute;
        left: 0;
        top: 5px;
        width: 3px;
        height: 14px;
        border-radius: 2px;
        background-color: #2c36de;
    }
}
// 标题
.title {
    font-size: 16px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
}
// 更多
.more {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    color: #999;
    cursor: pointer;

    span:nth-child(1) {
        margin-right: 4px;
    }
    span:nth-child(2) {
        transform: rotate(-270deg);
    }
}
.more:hover {
    color: #2c36de;
}
.more.active {
    span:nth-child(2) {
        transform: rotate(-90deg);
    }
}

.list {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px 12px;
    padding: 0;
}
.item {
    width: calc(50% - 6px);
    display: flex;
    align-items: center;
    cursor: pointer;
}

.frame {
    display: inline-block;
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    opacity: 0.9;
    border-radius: 2px;
    box-sizing: border-box;
    border: solid 1px #999;
}
.checked {
    width: 14px;
    height: 14px;
    position: relative;
}

.label {
    .maxLine(1);
    flex: 1;
    margin-left: 8px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
}
