@import '~@/styles/utils.less';

.ugc {
    padding: 24px 0;
    border-radius: 6px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
}
.ugc__header {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
}

// ----------------------------- 轮播图 Start -----------------------------
.ugc__swiper {
    margin-top: 24px;
    padding: 0 16px;
    cursor: pointer;
}
// ----------------------------- 轮播图 End -----------------------------
// ----------------------------- 列表项 Start -----------------------------
.list__item {
    width: 258px;
    height: 208px;
    display: flex;
    justify-content: flex-start;
}
.item__wrapper {
    width: 100%;
    height: 100%;
    padding: 24px 16px;
    box-sizing: border-box;
    border-radius: 8px;
    background: linear-gradient(to bottom, #fff, transparent);
}
// 头部
.header {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #e5e5e5;
}
.avatar {
    flex-shrink: 0;
    position: relative;
    width: 62px;
    overflow: hidden;
    height: 62px;
    flex-shrink: 0;
    border-radius: 62px;
    margin-right: 16px;
}
.info {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .name__wrapper {
        display: flex;
        flex-shrink: 0;
        align-items: flex-start;
    }
    .name {
        .maxLine(1);
        margin-right: 24px;
        flex: 1;
        font-size: 18px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-stretch: normal;
        font-style: normal;
        line-height: 26px;
        letter-spacing: normal;
        color: #262626;
        &:hover {
            color: #2c36de;
        }
    }
    .focus {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        position: relative;
    }
}
.intro {
    .maxLine(3);

    flex: 1;
    margin-top: 16px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: 0.07px;
    text-align: left;
    color: #666;
}
// ----------------------------- 列表项 End -----------------------------
