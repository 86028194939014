.flashNewsTit {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    position: relative;
    margin-bottom: 24px;

    span {
        display: block;

        &:nth-of-type(1) {
            width: 48px;
            height: 24px;
            margin-right: 6px;
            color: var(--pc-base-background);
            font-size: var(--pc-font-size-14);
            font-style: oblique;
            background-color: var(--pc-base-f5-color);
            border-radius: 12px 12px 0 12px;
            text-align: center;
            line-height: 24px;
            user-select: none;
        }

        &:nth-of-type(2) {
            margin-right: 8px;
            font-size: 24px;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            line-height: 24px;
            padding-bottom: 2px;
            color: var(--pc-normal-color);
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 4px;
        background-color: var(--pc-normal-hover-color);
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 104px;
        height: 4px;
        background-color: #262f2d;
    }
}

.flashNewsBox {
    width: 100%;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 9;
    background-color: var(--pc-line-background);

    :global {
        .swiper-button-disabled {
            display: none;
        }
    }
}

.flashNews {
    width: 100%;
    height: 40px;
    white-space: nowrap;
    margin-bottom: 24px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.flashNewsItem {
    width: auto;
    line-height: 40px;
    display: inline-block;
    font-size: 18px;
    color: var(--pc-base-f9-color);
    cursor: pointer;
    white-space: nowrap;
    user-select: none;

    .swiperItemTitle {
        position: relative;
        z-index: 1;
    }

    &.active {
        font-weight: 500;
        font-family: PingFangSC-Medium;
        position: relative;

        &::after {
            content: ' ';
            display: block;
            width: calc(100% - 2px);
            height: 12px;
            opacity: 0.5;
            background-color: var(--pc-primary-color);
            position: absolute;
            bottom: 15%;
            left: 50%;
            z-index: 0;
            transform: translateX(calc(-50%)) skew(-20deg);
        }
    }
}

.swiperBackPrev,
.swiperBackNext {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background-color: var(--pc-base-background);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.swiperBackPrev {
    left: 2px;
}

.swiperBackNext {
    right: 2px;
}

.swiperBtnPrev,
.swiperBtnNext {
    width: 48px;
    height: 36px;
    position: absolute;
    top: 60%;
    z-index: 9;
    background-color: var(--pc-line-background);

    &::after {
        font-size: 14px;
        color: var(--pc-normal-color);
        z-index: 2;
    }
}

.swiperBtnPrev {
    left: 0;

    &::after {
        margin-right: 10px;
    }
}

.swiperBtnNext {
    right: 0;

    &::after {
        margin-left: 10px;
    }
}

@media screen and (max-width: 628px) {
    .flashNewsBox {
        top: 64px;
    }
}
