.lineItem {
    display: inline-block;
    margin-right: 48px;
    letter-spacing: 0.5px;
    line-height: var(--pc-line-height-a);
    font-size: var(--pc-font-size-18);
    color: var(--pc-base-f9-color);

    &.active {
        position: relative;

        &::after {
            content: ' ';
            display: block;
            width: calc(100% - 2px);
            height: 12px;
            opacity: 0.2;
            background-color: #00e0b9;
            position: absolute;
            bottom: 0;
            left: 50%;
            z-index: -1;
            transform: translateX(calc(-50%)) skew(-20deg);
        }
    }
}

// @media screen and (max-width: 628px) {
//     .seriesItem {
//         font-size: var(--pc-font-size-14);

//         &.active {
//             font-weight: 500;
//             position: relative;
//         }
//     }
// }
