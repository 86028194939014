.container {
    position: relative;
    flex: 1;

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    .hasAuth {
        display: none;
    }

    .noAuth {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
    }
}

.conBox {
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    // overflow: auto;

    &.conBoxNoAuth {
        height: 80vh;
        overflow: hidden;
    }
}

.page {
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    // overflow: hidden;
}

.empty {
    height: 80px;
}
.RowBox_left {
    flex: 1;
    margin-right: 16px;
    max-width: 912px;
}
.RowBox_right {
    width: 332px;
}

.hanDom {
    display: flex;
    display: -webkit-flex;
    width: 100%;

    & > div {
        display: inline-block;
    }
}

.visualPage {
    position: relative;

    .hasAuth {
        display: none;
    }

    .noAuth {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
    }
}
@media screen and (max-width: 768px) {
    .RowBox_left {
        width: 100%;
        flex: none;
        margin-right: 0;
        max-width: none;
    }
    .RowBox_right {
        width: 100%;
    }
    .hanDom {
        flex-direction: column-reverse;
    }
}
