.wrapper__list {
    overflow: auto;
}
.list__item {
    & > div {
        border-bottom: 1px solid #e5e5e5;
    }
    &:last-child {
        & > div {
            border-bottom: none;
        }
    }
}

// ---------------- 滚动条 Start -----------------

.wrapper__list {
    /* 针对基于 Chromium 的 Edge 浏览器 */
    scrollbar-width: none;
    scrollbar-color: #e7e7e7 #fff;

    &:hover {
        scrollbar-width: thin;
        &::-webkit-scrollbar-thumb {
            display: block;
        }
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff !important;
        box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none;
        background-color: #e7e7e7 !important;
        width: 6px;
        border-radius: 6px;
    }
}

// ---------------- 滚动条 End -----------------
