.container {
    width: 100%;
    word-break: normal;

    h1,
    h3 {
        margin: 0;
        color: #333;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 18px;
        line-height: 1.44;
        letter-spacing: -0.41px;
        letter-spacing: 0.5px;
    }

    .tiemr {
        margin-top: 8px;
        color: #999;
        font-size: 14px;
    }

    .tip {
        height: 26px;
        line-height: 26px;
        margin-bottom: 12px;
        padding: 0 16px;
        color: #666;
        font-size: 12px;
        background-color: #f5f5f5;
        border-radius: 4px;
    }
}

.content {
    .img {
        position: relative;
        display: block;
        width: 100%;
        height: 332px;
        margin-top: 12px;

        img {
            object-fit: contain !important;
        }
    }

    .paragraph {
        margin-top: 12px;
        color: #333;
        font-size: 14px;
        line-height: var(--pc-line-height-a);
        white-space: pre-wrap;
    }
}

.skeleton {
    margin-top: 24px;
}
