.wrapper {
    ul {
        list-style: none;
    }

    :global {
        .ant-affix {
            z-index: 980;
            display: flex;
            align-items: center;
        }
    }
}

.margin_top {
    margin-top: 16px;
}

.first_navs_box {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    border-radius: 6px 6px 0 0;
    background-color: #262933;
    box-sizing: border-box;
}

.first_navs {
    width: 100%;
    height: 100%;

    :global {
        .swiper-wrapper {
            align-items: center;
        }
    }

    .first_item {
        position: relative;
        box-sizing: border-box;
        width: auto !important;
        height: 38px;
        color: rgba(255, 255, 255, 0.6);
        font-size: 18px;
        line-height: 38px;
        white-space: pre;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;

        &:hover,
        &.active {
            color: #fff;
        }

        &.active {
            box-sizing: border-box;
            min-width: 96px;
            padding: 0 12px;
            color: #fff;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            background-color: #3d4254;
        }

        &.more_active {
            &::after {
                position: absolute;
                bottom: -27px;
                left: calc(50% - 8px);
                box-sizing: border-box;
                width: 16px;
                height: 16px;
                background-color: #3d4254;
                border-top-left-radius: 2px;
                transform: rotate(45deg);
                content: '';
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.second_navs {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 64px;
    margin: 0;
    padding: 0 24px;
    background-color: #3d4254;

    :global {
        .swiper-wrapper {
            align-items: center;
        }
    }

    .second_item {
        width: auto !important;
        position: relative;
        z-index: 1;
        color: rgba(255, 255, 255, 0.6);
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover,
        &.active {
            color: #fff;
        }

        &.active {
            font-weight: 500;
            font-family: PingFangSC-Medium;
            color: #fff;
        }

        &:last-child {
            margin-right: 0;
            padding-right: 24px;
        }
    }
}

.top_tabs {
    display: flex;
    word-break: normal;
    background-color: #ffffff;
    margin-bottom: 16px;

    & > li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 27.75%;
        color: #262f2d;
        line-height: 1.5;
        letter-spacing: 0.5px;
        background-color: transparent;
        border: 1px solid #ebebeb;
        border-left: none;
        position: relative;

        &:first-child {
            width: 17.75%;
            padding: 16px;
            text-align: center;
            background-color: #fbfbfb;
            border-left: 1px solid #ebebeb;
        }

        &.no_right_border {
            border-right-color: transparent;
        }
    }

    .switch {
        width: 34px;
        height: 34px;
        position: absolute;
        top: 50%;
        right: -17px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .switch_dis {
        opacity: 0.6;
        cursor: not-allowed;
    }
}
