.swiperContainBox {
    box-sizing: border-box;

    :global {
        .ant-image-error,
        .ant-image-placeholder {
            background-color: #ebebeb;

            img {
                width: auto;
                object-fit: contain;
            }
        }
    }
}

@media screen and (max-width: 628px) {
    .swiperContainBox {
        display: none;
    }
}
