@import '~@/styles/utils.less';

.billboard {
    padding: 32px 16px;
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 6px;

    &:last-child {
        margin-bottom: 0;
    }
}

.billboard_top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        font-size: 24px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        flex: 1;
        margin-right: 40px;
        .maxLine(1);
        word-break: break-all;

        &:hover {
            color: var(--theme-color);
        }
    }

    .more {
        display: flex;
        align-items: center;
        color: #999;
        font-size: 14px;
        cursor: pointer;

        &:hover {
            color: var(--theme-color);
        }

        .arrow {
            width: 14px;
            height: 14px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 4px;
        }
    }
}

.billboard_info {
    font-size: 16px;
    line-height: 1.75;
    color: #262626;
    padding-top: 16px;
    cursor: pointer;
    .maxLine(3);
}

.billboard_list {
    width: 100%;
    margin: 16px 0 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(5, calc((100% - 64px) / 5));
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}
