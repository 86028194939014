.flashNewsDate {
    width: 100%;
    margin-bottom: 16px;
    color: var(--pc-visited-color);
    font-size: var(--pc-font-size-16);
    line-height: normal;
}

.flashNewsItem {
    position: relative;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 32px;
    padding: 24px;
    background-color: var(--pc-base-background);
    border-radius: 4px;

    .flashNewsTimer {
        width: 40px;
        padding-right: 24px;
        color: var(--pc-normal-color);
        font-size: var(--pc-font-size-16);
        white-space: pre;
    }

    .flashNewsCon {
        width: calc(100% - 72px);

        .flashNewsTitle {
            display: block;
            width: 100%;
            margin-bottom: 8px;
            overflow: hidden;
            color: var(--pc-normal-color);
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: var(--pc-font-size-18);
            line-height: normal;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;

            &:hover {
                color: var(--pc-primary-color);
            }

            & > span {
                color: var(--pc-base-f7-color);
            }
        }

        .flashNewsIntrod {
            display: -webkit-box;
            overflow: hidden;
            color: var(--pc-visited-color);
            font-size: var(--pc-font-size-16);
            line-height: 150%;
            white-space: pre-wrap;
            text-overflow: ellipsis;
            word-break: normal;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .sourceUrl {
            display: flex;
            justify-content: flex-start;

            &.sourceUrlNull {
                display: none;
            }

            a {
                color: #1e80ff;
            }
        }

        .flashNewsMsg {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: 28px;
        }

        .flashNewsTag {
            display: flex;
            align-items: center;
            line-height: 16px;

            a {
                margin-right: 8px;
                color: var(--pc-base-f1-color);
                font-size: var(--pc-font-size-14);

                &:hover {
                    color: var(--pc-primary-color);
                }

                &.searchOriginal {
                    color: var(--pc-base-f7-color);
                }
            }
        }

        .flashNewsIcon {
            display: flex;
            align-items: center;
        }
    }
}

@media screen and (max-width: 628px) {
    .flashNewsDate {
        padding: 0 24px;
    }
}
