.ugcRec {
  width: 278px;
  height: 600px;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
  .skeleton {
    padding: 24px;
  }
  .empty {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding-top: 100px;
    text-align: center;
    img {
      width: 100%;
    }
  
    span {
      margin-top: 31px;
      font-size: var(--pc-font-size-14);
      color: var(--pc-base-f1-color);
    }
  }
}