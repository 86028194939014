.wrapper {
    width: 100%;
    height: 100%;
    padding: 30px 0 26px;
    box-sizing: border-box;
    position: relative;
}

.main {
    width: 77%;
    margin: 0 auto;
    position: relative;
}

.logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    border: solid 1px transparent;
    position: relative;

    &.logo_border {
        border-color: #e5e5e5;
    }

    .img {
        width: 100%;
        height: 100%;
        position: relative;
    }
}

.close {
    width: 20px;
    height: 20px;
    display: block;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.change_btn_box {
    width: 100%;
    height: 34px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.change_btn {
    cursor: pointer;

    span {
        color: #999;
        font-size: 14px;
        line-height: 1.57;
        text-align: right;
    }

    span:first-child {
        display: block;
    }

    span:last-child {
        display: none;
        color: var(--theme-color);
    }

    &:hover {
        span:first-child {
            display: none;
        }

        span:last-child {
            display: block;
        }
    }
}
