@import url('~@/styles/utils.less');
.topic {
    background-color: #fff;
    border-radius: 6px;
    position: relative;
    z-index: 10;
}
.topic_top {
    z-index: 3;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 24px 16px 0;
}
.tab_wrapper {
    flex: 1;
    display: flex;
    margin-left: 40px;
    align-items: center;
    gap: 24px;
    .tab_item {
        font-size: 18px;
        line-height: 26px;
        color: var(--color-text-primary);
        cursor: pointer;
    }
    .tab_item.active {
        color: var(--theme-color);
        font-weight: 500;
        font-family: PingFangSC-Medium;
    }
}
.list {
    display: flex;
    flex-direction: column;
}
.list_item {
    padding: 16px;
    width: 100%;
    height: 672px;
    box-sizing: border-box;
    display: flex;
    gap: 16px;
}
// -------------------- 列表项左侧 Start --------------------
.list_item_left {
    flex-shrink: 0;
    display: block;
    width: 447px;
}
.img_wrapper {
    height: 447px;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
}

.title {
    .maxLine(1);
    margin-top: 16px;
    font-size: 24px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    line-height: 1.5;
    letter-spacing: 0.12px;
    color: var(--color-text-primary);
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        color: var(--theme-color);
    }
}
.introduction {
    .maxLine(3);
    margin-top: 8px;
    font-size: 14px;
    color: #666;
    line-height: 1.57;
    letter-spacing: 0.07px;
}
.focus {
    margin-top: 32px;
    flex-shrink: 0;
    padding: 0 24px;
    border-radius: 4px;
    height: 34px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 34px;
    letter-spacing: -0.41px;
    text-align: center;
    color: var(--color-text-primary);
    background-color: #f5f5f5;
    cursor: pointer;
    width: max-content;

    picture {
        width: 14px;
        height: 14px;
    }
    img {
        width: 14px;
        height: 14px;
    }
    span {
        flex-shrink: 0;
    }

    &.not {
        background-color: var(--theme-color);
        color: var(--invalid-name);
        &:hover {
            background-color: var(--btn-hover-color);
        }
    }
}
// -------------------- 列表项左侧 End --------------------
// -------------------- 列表项右侧文章列表 Start --------------------
.list_item_right {
    flex: 1;
}
// -------------------- 列表项右侧文章列表 End --------------------
.active_index_con {
    display: flex;
    margin-top: 20px;
    gap: 40px;
    margin-bottom: 32px;
}
.data_length {
    width: 60px;
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    line-height: 1.5;
    letter-spacing: 0.08px;
    border: solid 1px #e5e5e5;
}
.wrapper__more {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    cursor: pointer;
    color: #999;
    &:hover {
        color: #2c36de;
    }
    .text__more {
        font-weight: normal;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        display: flex;
        align-items: center;
        letter-spacing: normal;
    }
    .arrow__more {
        font-size: 18px;
        line-height: normal;
    }
}

.wrapper__title {
    position: relative;
    flex-shrink: 0;
    h2 {
        position: relative;
        z-index: 1;
        color: #000;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 24px;
        font-style: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: normal;
    }

    .top_bg_icon {
        position: absolute;
        top: -10px;
        background-size: 23px 21px;
        left: -10px;
        width: 23px;
        height: 21px;
    }
}

.img_con:hover {
    img {
        transform: scale(1.1);
        transition: all 0.5s;
    }
}
