@import '~@/styles/utils.less';

.wrapper {
    display: flex;
    flex-direction: column;
    height: 335px;
    padding: 12px 12px 0;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: #fff;
}

.recommend_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.recommend_box {
    overflow: hidden;
}

.recommend_list {
    margin-bottom: 0;
    padding: 0;
    height: 100%;

    li {
        position: relative;
        padding: 12px 0;

        &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: #e5e5e5;
            content: '';
        }

        &.no_bottom::after {
            display: none;
        }
    }
}

.null_data {
    height: 100%;
}
