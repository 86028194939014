.seriesItem {
    display: inline-block;
    margin-right: 48px;
    font-size: var(--pc-font-size-18);
    color: var(--pc-base-f9-color);

    // &:last-child {
    //     margin-right: 0;
    // }

    &.active {
        // color: var(--pc-primary-color);
        font-weight: 500;
        font-family: PingFangSC-Medium;
        position: relative;

        &::after {
            content: ' ';
            display: block;
            width: calc(100% - 2px);
            height: 12px;
            opacity: 0.2;
            background-color: var(--pc-primary-color);
            position: absolute;
            bottom: 15%;
            left: 50%;
            z-index: -1;
            transform: translateX(calc(-50%)) skew(-20deg);
        }
        &.has_underline {
            padding-bottom: 10px;
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0;
                background-color: #2c36de;
            }
        }
    }
}

@media screen and (max-width: 628px) {
    .seriesItem {
        font-size: var(--pc-font-size-14);
        margin-right: 24px;
        &.active {
            font-weight: 500;
            font-family: PingFangSC-Medium;
            position: relative;

            &::after {
                bottom: 20%;
            }
        }
    }
}

.barkly_container {
    &.active {
        &.has_underline {
            &::before {
                height: 1px;
                z-index: 10;
                background-color: #fff;
            }
        }
    }
}
