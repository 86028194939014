.wrapper {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 24px 0;
    background-color: #ffffff;
    border-radius: 6px;
}

.wrapper_left {
    width: calc((848 / 1376) * 100%);
}

.wrapper_right {
    width: calc((506 / 1376) * 100%);
}
