.wrapper {
  position: relative;
  margin-top: 12px;
  &.shade::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}