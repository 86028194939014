@import '~@/styles/utils.less';

.ugc {
    display: flex;
    margin: 0;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}
.ugc__item {
    flex-shrink: 0;
    display: flex;
    width: 121px;
    height: 160px;

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 8px;
    margin-right: 12px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: var(--invalid-name);

    &:last-child {
        margin-right: 0;
    }
}
.avatar {
    width: 44px;
    height: 44px;
    overflow: hidden;
    position: relative;
    border-radius: 44px;
    object-fit: cover;
    box-sizing: border-box;
}

.name {
    .maxLine(1);

    margin-top: 8px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
}

.intro {
    .maxLine(1);

    margin-top: 2px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    text-align: center;
    letter-spacing: -0.41px;
    color: rgba(0, 0, 0, 0.45);
}

.button {
    height: 26px;
    flex-grow: 0;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    margin-top: 8px;
    padding: 2px 12px;
    border-radius: 4px;
    border: solid 1px #2c36de;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    color: #2c36de;
    text-wrap: nowrap;

    span {
        &:nth-child(1) {
            font-size: 12px;
            font-weight: 500;
            font-family: PingFangSC-Medium;
        }
    }
}
.button.focus {
    background-color: #2c36de;
    color: var(--invalid-name);
}
