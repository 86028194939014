.lineItem {
    display: inline-block;
    margin-right: 25px;
    letter-spacing: 0.5px;
    line-height: 1.63;
    font-size: var(--pc-font-size-16);
    color: var(--pc-base-f9-color);

    &.active {
        position: relative;

        &::after {
            content: ' ';
            display: block;
            width: calc(100% + 6px);
            height: 12px;
            opacity: 0.2;
            background-color: #00e0b9;
            position: absolute;
            bottom: 0;
            left: 50%;
            z-index: -1;
            transform: translateX(calc(-50%)) skew(-10deg);
        }
    }
}

// @media screen and (max-width: 628px) {
//     .seriesItem {
//         font-size: var(--pc-font-size-14);

//         &.active {
//             font-weight: 500;
//             position: relative;
//         }
//     }
// }
