@import '~@/styles/utils.less';

.banner_con {
    position: relative;
    display: block;
    width: 100%;
    height: 275px;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 16px;

    .banenr_mask {
        position: absolute;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
    }

    .banenr_tip {
        position: absolute;
        bottom: 40px;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        padding: 0 40px;
        overflow: hidden;
        color: #ffffff;
        font-size: 24px;
        line-height: 1.5;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        .maxLine(1);
        word-break: break-all;

        a {
            color: #ffffff;
        }
    }
}
