.wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main {
    width: 142px;
    height: 90px;
    margin: 0 auto;
    position: relative;
}

.logo {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    border: solid 1px #e5e5e5;

    .img {
        width: 100%;
        height: 100%;
        position: relative;
    }
}

.close {
    width: 16px;
    height: 16px;
    display: block;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
}

.add_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .add_icon {
        font-size: 14px;
        color: #666;
    }

    .add_text {
        font-size: 12px;
        color: #666;
        line-height: 1.5;
        letter-spacing: -0.41px;
        margin-top: 8px;
    }
}
