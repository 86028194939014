.listItem {
    position: relative;
    // width: 378.5px;
    height: 272px;
    margin-bottom: 24px;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        transform: translateY(-5px);
    }
}

.listItemTitleBox {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    // width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 111px;
    padding: 0 16px 12px;
    background-image: linear-gradient(to bottom, rgba(38, 47, 45, 0), #262f2d);
}

.listItemTitle {
    display: -webkit-box; //特别显示模式
    overflow: hidden; //溢出内容隐藏
    color: #fff;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    line-height: 1.2;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    word-break: normal;
    -webkit-line-clamp: 2; //行数
    line-clamp: 2;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
}

@media screen and (max-width: 415px) {
    .topicBox {
        box-sizing: border-box;
        padding: 0 24px !important;
    }

    .listItem {
        margin-bottom: 16px;
        transition: none;

        &:hover {
            transform: translateY(0);
        }
    }
}
