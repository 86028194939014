@import '~@/styles/utils.less';

.wrapper {
    display: flex;
    flex-direction: column;
    height: 365px;
    padding: 12px 12px 0;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: #fff;
}

.billboard_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
}

.maxLine {
    max-width: 238px;
    .maxLine(1);
    word-break: break-all;
}

.list {
    display: flex;
    align-items: center;
    width: 100%;
    height: 22px;
    margin-top: 8px;
}

.list_item {
    width: auto !important;
    padding: 0 12px;
    color: #262626;
    line-height: 1.57;
    max-width: 150px;
    .maxLine(1);
    word-break: break-all;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    &.active {
        color: var(--theme-color);
    }

    &.active {
        font-weight: 500;
        font-family: PingFangSC-Medium;
    }
}

.more {
    display: flex;
    align-items: center;
    color: #999;
    font-size: 12px;
    cursor: pointer;

    &:hover {
        color: var(--theme-color);
    }

    .arrow {
        font-size: 12px;
    }
}

.details_list_box {
    position: relative;
}

.details_list {
    padding: 0;

    li {
        position: relative;
        padding: 12px 0;

        &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: #e5e5e5;
            content: '';
        }

        &.no_bottom::after {
            display: none;
        }
    }
}

.null_data {
    flex: 1;
}
