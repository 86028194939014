@import '~@/styles/utils.less';

@header-height: 30px;

.wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 24px 0;
    background-color: #fff;
    border-radius: 6px;
}

.top {
    display: flex;
    align-items: center;
    width: 100%;
    height: @header-height;
    padding: 0 16px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 8px;
}

.topTitle {
    margin-right: 40px;
}

.maxLine {
    max-width: 332px;
    .maxLine(1);
    word-break: break-all;
}

.list {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    pointer-events: auto;
    padding-right: 120px;
    box-sizing: border-box;
}

.list_item {
    width: auto !important;
    padding: 0 12px;
    color: #262626;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    line-height: 30px;
    max-width: 177px;
    .maxLine(1);
    word-break: break-all;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    &:hover,
    &.active {
        color: var(--theme-color);
    }

    &.active {
        font-weight: 500;
        font-family: PingFangSC-Medium;
    }
}

.more {
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 152px;
    position: absolute;
    right: 16px;
    z-index: 1;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 61%);

    .more_con {
        color: #999;
        font-size: 14px;
        display: flex;
        align-items: center;
        height: 100%;
        background-color: #fff;
        cursor: pointer;
        pointer-events: auto;
        box-sizing: border-box;

        &:hover {
            color: var(--theme-color);
        }
    }

    .arrow {
        font-size: 14px;
    }

    &.moreLast {
        background-image: none;
    }
}

.topLine {
    height: 30px;
    margin-bottom: 0;

    .list {
        box-sizing: border-box;
        height: 100%;
    }

    .list .list_item {
        box-sizing: border-box;
        line-height: 30px;
    }

    .more {
        .more_con {
            height: 30px;
        }
    }
}

.broker_list_box {
    position: relative;
}

.broker_list {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(6, 1fr);
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
    margin: 0;

    li a {
        position: relative;
        display: flex;
        align-items: center;
        padding: 16px 0;
    }

    li a:hover {
        .name {
            color: var(--theme-color);
        }
    }

    li .logo {
        position: relative;
        box-sizing: border-box;
        width: 60px;
        height: 60px;
        margin-right: 12px;
        overflow: hidden;
        border-radius: 4px;
        background-color: #ffffff;
        border: solid 1px #e5e5e5;
    }

    li .logo img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    li .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 72px);
        height: 60px;
    }

    li .info p {
        margin: 0;
    }

    li .info .name {
        width: 100%;
        margin-bottom: 4px;
        color: #262626;
        font-size: 16px;
        .maxLine(1);
        word-break: break-all;
    }

    li .info .score {
        display: flex;
        align-items: center;

        .icon {
            position: relative;
            width: 24px;
            height: 24px;
            margin-right: 4px;
        }

        .num {
            color: #262626;
            font-size: 18px;
            line-height: 1;
        }
    }
}

.no_more {
    width: 100%;
}

.null_data {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
