.expansion {
    display: flex;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;

    :global {
        .ant-typography {
            white-space: normal;
            font-size: 16px;
            line-height: 1.75;
            position: relative;
            margin-bottom: 0;
        }
    }

    &.ex_open {
        :global {
            .ant-typography {
                white-space: pre-wrap;
            }
        }
    }
}

.text {
    text-align: justify;

    & > span {
        display: inline-block;
        white-space: nowrap;
    }

    :global {
        .ant-typography-expand {
            flex: 1;
            text-align: right;
        }
    }
}

.drop_btn {
    width: 80px;
    height: 100%;
    display: inline-block;
    cursor: pointer;
    color: #999;
    white-space: nowrap;
    font-size: 14px;

    &:hover {
        color: var(--theme-color);
    }

    .arrow_box {
        width: 14px;
        height: 100%;
        display: inline-block;
        margin-left: 4px;
    }

    .arrow {
        width: 14px;
        height: 100%;
        font-size: 14px;
        display: inline-block;
        transform: rotateZ(90deg);
        transform-origin: 50% 50%;
    }
}

.drop_btn_posi {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 26px;
}

.drop_up_btn {
    display: inline-block;
    margin-left: 8px;

    .arrow {
        height: auto;
        display: inline-block;
        transform: rotateZ(-90deg);
    }
}
