@import '~@/styles/utils.less';

.wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 272px;
    padding: 12px 0 16px;
    background-color: #fff;
    border-radius: 6px;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 24px;
    padding: 0 12px;
    box-sizing: border-box;
}

.maxLine {
    max-width: 238px;
    .maxLine(1);
    word-break: break-all;
}

.list {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 12px;
    line-height: 22px;
    box-sizing: border-box;
}

.list_item {
    width: auto !important;
    padding: 0 12px;
    color: #262626;
    cursor: pointer;
    user-select: none;
    margin-top: 8px;
    max-width: 150px;
    .maxLine(1);
    word-break: break-all;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    &:hover,
    &.active {
        color: var(--theme-color);
    }

    &.active {
        font-weight: 500;
        font-family: PingFangSC-Medium;
    }
}

.more {
    display: flex;
    align-items: center;
    color: #999;
    font-size: 12px;
    cursor: pointer;

    &:hover {
        color: var(--theme-color);
    }

    .arrow {
        font-size: 12px;
    }
}

.broker_list_box {
    position: relative;
    margin-top: 12px;
}

.broker_list {
    display: grid;
    grid-gap: 12px 0;
    grid-template-columns: repeat(2, 1fr);
    box-sizing: border-box;
    width: 100%;
    margin: 0;

    li:nth-of-type(odd) {
        border-right: 1px solid #e5e5e5;
    }

    li a {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 12px;
    }

    li .logo {
        position: relative;
        box-sizing: border-box;
        width: 50px;
        height: 50px;
        margin-right: 12px;
        overflow: hidden;
        border-radius: 4px;
        background-color: #ffffff;
        border: solid 1px #e5e5e5;
    }

    li .logo img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    li .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 62px);
        height: 50px;
    }

    li .info p {
        margin: 0;
    }

    li .info .name {
        width: 100%;
        color: #262626;
        .maxLine(1);
        word-break: break-all;
    }

    li .info .score {
        display: flex;
        align-items: center;

        .icon {
            position: relative;
            width: 16px;
            height: 16px;
            margin-right: 4px;
        }

        .num {
            color: #262626;
            font-size: 12px;
            line-height: 1;
        }
    }
}

.no_more {
    width: 100%;
}

.null_data {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
