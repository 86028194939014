@import url('~@/styles/utils.less');

.wrapper {
    background-color: #fff;
    border-radius: 6px;
    position: relative;
}
// -------------------- 头部 Start --------------------
.header {
    z-index: 3;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 24px 16px 0;
}
.header_left {
    display: flex;
    gap: 40px;
    justify-content: space-between;
}
.tab_wrapper {
    display: flex;
    gap: 24px;
    .tab_item {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 26px;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
        cursor: pointer;
        &.active {
            color: var(--theme-color);
            font-weight: 500;
            font-family: PingFangSC-Medium;
        }
    }
}

.wrapper__more {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    cursor: pointer;
    color: #999;
    &:hover {
        color: #2c36de;
    }
    .text__more {
        font-weight: normal;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        display: flex;
        align-items: center;
        letter-spacing: normal;
    }
    .arrow__more {
        font-size: 18px;
        line-height: normal;
    }
}
// -------------------- 头部 End --------------------
// -------------------- 轮播图 Start --------------------
.swiper_list {
    padding: 24px 16px;
}

.img_con {
    position: relative;
    overflow: hidden;
}
.text_content {
    font-size: 18px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    line-height: 26px;
    .maxLine(1);
}

.ugc_swiperSlideBox,
.topic_swiperSlideBox {
    cursor: pointer;
    gap: 16px;
    border-radius: 4px;
    overflow: hidden;
    background-color: var(--invalid-name);
    display: flex;
    flex-direction: column;
    position: relative;
}
.ugc_swiperSlideBox {
    width: 158px;
    height: 200px;
    box-sizing: border-box;
    padding: 24px;
    justify-content: center;
    align-items: center;
    .img_con {
        width: 110px;
        height: 110px;
        border-radius: 110px;
    }

    &.active,
    &:hover {
        box-shadow:
            0 3px 14px 2px rgba(0, 0, 0, 0.05),
            0 8px 10px 1px rgba(0, 0, 0, 0.06),
            0 5px 5px -3px rgba(0, 0, 0, 0.1);
    }
}

.topic_swiperSlideBox {
    width: 198px;
    height: 256px;
    align-items: center;
    .img_con {
        width: 198px;
        height: 198px;
    }
    .text_content {
        padding: 0 10px;
    }

    &.active,
    &:hover {
        box-shadow:
            0 5px 10px -3px rgba(0, 0, 0, 0.05),
            0 8px 14px 1px rgba(0, 0, 0, 0.05),
            0 3px 14px 2px rgba(0, 0, 0, 0.04);
    }
}
.more_swiperSlideBox:hover {
    box-shadow: none;
}

.go_more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 100%;
}
.more_icon {
    font-size: 34px;
    line-height: 34px;
    color: var(--color-text-explain);
}
.more_text {
    font-size: 14px;
    line-height: 22px;
    color: var(--color-text-explain);
}

// -------------------- 轮播图 End --------------------
// -------------------- 稿件列表 Start --------------------
.article_list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px 16px;
    &_border {
        height: 678px;
        border: solid 1px #e5e5e5;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        gap: 8px;
        position: relative;
    }
    &_item {
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        cursor: pointer;
        gap: 16px;
        &:hover {
            background-color: var(--list-hover-background);
            .article_list_info_title {
                color: var(--theme-color);
            }
        }
    }
    &_cover {
        width: 283px;
        height: 188px;
        flex-grow: 0;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        flex-shrink: 0;
    }
    &_info {
        flex: 1;
        display: flex;
        flex-direction: column;
        &_title {
            .maxLine(1);
            flex-shrink: 0;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: 18px;
            line-height: 26px;
            color: var(--color-text-primary);
        }
        &_intro {
            flex: 1;
            padding-top: 8px;
            &_text {
                .maxLine(3);
                flex: 1;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.07px;
                text-align: left;
                color: #666;
            }
        }
        &_bottom {
            flex-shrink: 0;
            display: flex;
            justify-content: space-between;
        }
    }
    .timer {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: normal;
        text-align: left;
        color: #999;
    }
    .tags {
        display: flex;
        align-items: center;
        span {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 22px;
            letter-spacing: normal;
            color: #999;
            display: flex;
            align-items: center;

            &::after {
                content: '';
                background-color: #999;
                height: 14px;
                width: 1px;
                display: inline-block;
                margin: 0 6px;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            &:hover {
                color: var(--theme-color);
            }
        }
    }
}
// -------------------- 稿件列表 End --------------------
// -------------------- 箭头 Start --------------------
.top_icon {
    .bg__default();
    position: absolute;
    display: block;
    width: 26px;
    height: 9px;
    left: calc(50% - 13px);
    top: -9px;
    background-color: var(--invalid-name);
}
// -------------------- 箭头 End --------------------
