.wrapper {
    padding: 12px 10px;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
}

.list__item {
    & > div {
        border-bottom: 1px solid #e5e5e5;
    }
    &:last-child {
        & > div {
            border-bottom: none;
        }
    }
}
