.wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 100vw;
    width: calc(100vw - 42px);
    background-color: var(--invalid-name);
    transition: right 0.5s;
    z-index: 99;
}
.wrapper.active {
    right: 42px;
    &::after {
        content: '';
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
    }
}
.switch {
    z-index: 9;
    position: absolute;
    right: -30px;
    top: 157px;
    width: 30px;
    height: 62px;
    flex-grow: 0;
    padding: 13px 8px;
    box-shadow: 4px 0 16px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    background-color: #2c36de;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    color: var(--invalid-name);
    border-radius: 0 8px 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.switch.active {
    width: 18px;
    right: -18px;
    background-color: var(--invalid-name);
    box-shadow: none;
    span {
        font-size: 16px;
        color: #262626;
        transform: rotate(180deg);
    }
}

// ------------------------------ 业务 Start ------------------------------
.business {
    overflow-x: auto;
    max-width: 100%;
    display: flex;
    flex-shrink: 0;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #f5f5f5;
    &::-webkit-scrollbar {
        display: none;
    }
}
.business__item {
    flex-shrink: 0;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    color: #262626;
}
.business__item.active {
    color: #2c36de;
    background-color: var(--invalid-name);
}
// ------------------------------ 业务 End ------------------------------

.filter {
    position: relative;
    flex: 1;
    overflow-y: auto;
    z-index: 99;
    width: 100%;
    background-color: var(--invalid-name);
    height: 100%;
    &::-webkit-scrollbar {
        display: none;
    }
    :global {
        .ant-slider-mark-text {
            color: #999 !important;
        }
    }
}
