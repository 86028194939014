.topicBox {
    background-color: #fff;
    border-radius: 4px;
}

.top_box {
    z-index: 3;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 12px 12px 10px 12px;
    .top_title {
        position: relative;
        font-size: 16px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }
}

.wrapper__title {
    position: relative;
    flex-shrink: 0;
    h2 {
        position: relative;
        font-size: 16px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }
}

.wrapper__more {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    cursor: pointer;
    color: #999;
    &:hover {
        color: #2c36de;
    }
    .text__more {
        font-weight: normal;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        display: flex;
        align-items: center;
        letter-spacing: normal;
    }
    .arrow__more {
        font-size: 16px;
        line-height: normal;
    }
}
.swiper_list {
    padding: 0 12px;
    :global {
        .swiper-pagination {
            bottom: 4px;
            .swiper-pagination-bullet {
                width: 28px;
                height: 2px;
                border-radius: 0px;
            }
        }
    }
    .swiper_box_item {
        overflow: hidden;
        width: 100%;
        border-radius: 4px;
        background-image: linear-gradient(180deg, #e9f5ff, rgba(246, 246, 247, 0.1));
    }
    .text_content {
        margin-left: 8px;
        flex: 1;
    }
    .swiperSlideTitleText {
        font-size: 14px;
        display: -webkit-box;
        font-weight: normal;
        letter-spacing: -0.41px;
        line-height: 1.57;
        color: #000;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
    }
    .introduction {
        display: -webkit-box;
        font-size: 12px;
        color: #666;
        line-height: 1.5;
        overflow: hidden;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        word-break: normal;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}
.swiperSlideBox {
    display: flex;
    height: 112px;
    padding: 10px 12px 14px 12px;
    .img_con {
        width: 88px;
        height: 88px;
        position: relative;
        overflow: hidden;
        border-radius: 4px;
    }
}
.article_list {
    margin-top: 10px;
}
