.wrapper {
    position: relative;
    &.shade::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
}

.market_con {
    border-radius: 6px;
    :global {
        .swiper-wrapper {
            border-radius: 6px 6px 0 0;
            background: #fff;
            padding-top: 24px;
        }
        .swiper-slide {
            position: relative;
            // line-height: 60px;
            // padding: 0 40px;
            margin: 16px 40px 16px 0;

            &::after {
                position: absolute;
                top: 32%;
                right: 0;
                display: block;
                width: 1px;
                height: 35%;
                background-color: #e5e5e5;
                content: '';
            }
            &:first-of-type {
                margin-left: 24px;
            }
            &:last-of-type {
                margin-right: 24px;
                &::after {
                    display: none;
                }
            }
        }
    }
}
