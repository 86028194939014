.RowBox_left {
    max-width: 1028px;
    flex: 1;
    margin-right: 16px;
}

.RowBox_right {
    width: 332px;
}

@media screen and (max-width: 912px) {
    .RowBox_left {
        margin-right: 0;
    }
    .RowBox_right {
        display: none !important;
    }
}
