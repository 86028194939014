.keyWordBox {
    background: #fff;
    padding: 12.5px 0 13.5px 12px;
    border-radius: 6px;
}

.keyWords {
    // width: 100%;
    height: 22px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.keyWordItem {
    width: auto;
    line-height: 22px;
    display: inline-block;
    padding: 0 10px;
    cursor: pointer;
    white-space: nowrap;
    line-height: 1.57;

    a {
        font-size: var(--pc-font-size-14);
        color: var(--pc-base-f10-color);
    }

    &:hover {
        a {
            color: var(--theme-color);
        }
    }

    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 65%;
        position: absolute;
        top: 52%;
        right: 0;
        transform: translateY(-50%) rotateZ(25deg);
        background-color: #000000;
    }

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 12px;

        &::after {
            display: none;
        }
    }
}

// @media screen and (max-width: 998px) {
//     .keyWordItem {
//         a {
//             font-size: var(--pc-font-size-16);
//         }
//     }
// }

// @media screen and (max-width: 628px) {
//     .keyWordBox {
//         // display: none;
//     }
// }
