@import '~@/styles/utils.less';

.billboard {
    padding: 12px 12px 0;
    background-color: #fff;
    margin-bottom: 12px;
    border-radius: 6px;

    &:last-child {
        margin-bottom: 0;
    }
}

.billboard_top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        font-size: 16px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin-right: 16px;
        .maxLine(1);
        word-break: break-all;
    }

    .more {
        display: flex;
        align-items: center;
        color: #999;
        font-size: 12px;
        cursor: pointer;

        .arrow {
            width: 12px;
            height: 12px;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 4px;
        }
    }
}

.billboard_info {
    line-height: 1.57;
    color: #262626;
    padding-top: 12px;
    text-align: justify;
    .maxLine(3);
}

.billboard_list {
    width: 100%;
    margin: 0;
    padding: 0;

    li {
        border-bottom: 1px solid #e5e5e5;

        &:last-child {
            border-bottom: none;
        }
    }
}

.billboard_list_null {
    height: 12px;
}
