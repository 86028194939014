// ---------------------------- 交易商对比样式
.contrastBroker {
    box-sizing: border-box;
    width: 100%;
    padding: 16px 24px 24px;
    background-color: #ffffff;

    & > h2 {
        margin-bottom: 16px;
        color: #010b27;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.75px;
    }

    .contrastList {
        display: grid;
        grid-column-gap: 24px;
        grid-template-columns: repeat(3, calc((100% - 48px) / 3));

        & > li {
            position: relative;
            box-sizing: border-box;
            height: 140px;
            padding: 7px 16px 24px;
            background-color: #fbfbfb;
            border: 1px solid #fbfbfb;
            border-radius: 2px;

            &.stop {
                .botCon {
                    display: flex;
                    align-items: center;

                    .logo {
                        -webkit-filter: grayscale(100%);
                        -moz-filter: grayscale(100%);
                        -ms-filter: grayscale(100%);
                        -o-filter: grayscale(100%);
                        filter: grayscale(100%);
                        filter: gray;
                    }

                    .info {
                        h2 {
                            color: #999999;
                        }

                        .score {
                            .tit {
                                color: #999999;
                            }

                            .num {
                                color: #999999;
                            }
                        }

                        .starList {
                            height: 19px;
                        }

                        .starItem {
                            width: 13.6px;
                            height: 13.6px;
                            margin-right: 5px;
                            margin-left: 0;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            &:hover {
                border-color: #00d5b0;
            }

            .topCon {
                margin-bottom: 8px;
                color: #999999;
                line-height: 1.5;
                letter-spacing: 0.5px;
                text-align: right;

                & > span {
                    cursor: pointer;

                    .b {
                        display: none;
                        color: var(--pc-primary-color);
                    }

                    &:hover {
                        .a {
                            display: none;
                        }

                        .b {
                            display: inline;
                        }
                    }
                }
            }

            .botCon {
                display: flex;
                align-items: center;

                .logo {
                    position: relative;
                    box-sizing: border-box;
                    width: 72px;
                    height: 72px;
                    margin-right: 16px;
                    overflow: hidden;
                    background-color: #ffffff;
                    border: solid 1px #ebebeb;
                    border-radius: 36px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: calc(100% - 88px);
                    min-height: 80px;

                    h2 {
                        display: -webkit-box;
                        margin-bottom: 6px;
                        overflow: hidden;
                        color: #262f2d;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 150%;
                        letter-spacing: 0.5px;
                        white-space: pre-wrap;
                        text-overflow: ellipsis;
                        text-overflow: -o-ellipsis-lastline;
                        word-break: normal;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                    }

                    .msg {
                        display: flex;
                        align-items: flex-end;
                    }

                    .score {
                        display: flex;
                        align-items: flex-end;
                        margin: 0 8px 0 0;

                        .tit {
                            display: none;
                            color: #262f2d;
                            font-size: 10px;
                            letter-spacing: 0.36px;
                        }

                        .num {
                            color: #fe3434;
                            font-weight: 500;
                            font-family: PingFangSC-Medium;
                            font-size: 18px;
                            font-family: D-DIN;
                            line-height: 1;
                            letter-spacing: 0.45px;
                            text-align: center;
                        }
                    }

                    .starList {
                        height: 19px;
                    }

                    .starItem {
                        width: 13.6px;
                        height: 13.6px;
                        margin-right: 5px;
                        margin-left: 0;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            .botConEdit {
                .logo {
                    img {
                        object-fit: contain;
                    }
                }
            }

            .topEdit {
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                padding-top: 17px;
            }

            .changeCon {
                display: none;
                margin-top: 7px;
                white-space: pre;
                text-align: center;

                span {
                    color: #00d5b0;
                    font-size: 10px;
                    letter-spacing: 0.5px;
                    white-space: nowrap;
                }

                .arrow {
                    color: #666666;
                }
            }
        }
    }

    .clearBtn {
        margin-top: 24px;
        margin-bottom: 8px;
        color: #999999;
        font-size: 14px;
        letter-spacing: 0.5px;
        text-align: center;

        span {
            cursor: pointer;

            &:hover {
                color: var(--pc-primary-color);
            }
        }
    }

    .subBtn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 322px;
        height: 48px;
        margin: 0 auto;
        background-color: #00d5b0;
        border: none;
        border-color: #00d5b0;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            .back {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.2);
            }
        }

        span {
            position: relative;
            z-index: 1;
            color: #262f2d;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.5px;
        }

        &:disabled {
            background-color: #cccccc;
            border-color: #cccccc;
        }
    }
}

@media screen and (max-width: 1184px) {
    .contrastBroker {
        padding: 16px;

        .contrastList {
            display: grid;
            grid-column-gap: 16px;
            grid-template-columns: repeat(3, calc((100% - 32px) / 3));

            & > li {
                padding: 7px 16px 16px;

                .botCon {
                    .info {
                        h2 {
                            font-size: 16px;
                        }

                        .msg {
                            flex-wrap: wrap;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 998px) {
    .contrastBroker {
        padding: 16px;

        .contrastList {
            & > li {
                height: 150px;
                padding: 16px 12.7px 11px;

                &.stop {
                    .botCon {
                        .info {
                            .starList {
                                height: 11.3px;
                            }

                            .starItem {
                                width: 7.4px;
                                height: 7.4px;
                                margin-right: 2px;
                            }
                        }
                    }
                }

                .topCon {
                    display: none;
                }

                .botCon {
                    flex-direction: column;
                    justify-content: center;

                    .logo {
                        width: 45px;
                        height: 45px;
                        margin: 0;
                    }

                    .info {
                        justify-content: flex-start;
                        width: 100%;
                        min-height: 0;
                        margin-top: 8px;

                        h2 {
                            margin-bottom: 2px;
                            font-weight: 500;
                            font-family: PingFangSC-Medium;
                            font-size: 14px;
                            text-align: center;
                            -webkit-line-clamp: 1;
                            line-clamp: 1;
                        }

                        .msg {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }

                        .score {
                            align-items: center;
                            margin: 0 0 3.5px;

                            .tit {
                                display: block;
                                line-height: 1.1;
                            }

                            .num {
                                font-weight: normal;
                                font-size: 14px;
                            }
                        }

                        .starList {
                            display: flex;
                            height: 11.3px;
                        }

                        .starItem {
                            width: 7.4px;
                            height: 7.4px;
                            margin-right: 2px;
                        }
                    }
                }

                .changeCon {
                    display: block;
                }
            }
        }

        .clearBtn {
            display: none;
        }

        .subBtn {
            margin-top: 16px;
        }
    }
}

@media screen and (max-width: 628px) {
    .contrastBroker {
        padding: 0;

        .contrastList {
            & > li {
                &:hover {
                    border-color: #fbfbfb;
                }
            }
        }

        .subBtn {
            width: 100%;
            height: 40px;

            span {
                font-size: 14px;
            }

            &:disabled {
                background-color: #cccccc;
                border-color: #cccccc;
            }
        }
    }
}

@media screen and (max-width: 415px) {
    .contrastBroker {
        padding: 0 24px;

        & > h2 {
            margin-bottom: 12px;
            font-size: 18px;
        }
    }
}
