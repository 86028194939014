.video {
    position: relative;
    width: 100%;
    height: 865px;
}
// ------------------- 黑色背景 Start -------------------
.video__background {
    position: absolute;
    left: 0;
    width: calc(100vw - 5px);
    margin-left: 50%;
    transform: translateX(-50vw);
    height: 865px;
    object-fit: cover;
    -webkit-backdrop-filter: blur(22.1px);
    backdrop-filter: blur(22.1px);
    background-color: #262626;
}
// ------------------- 黑色背景 End -------------------

.video__container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
}
.video__preview {
    width: 772px;
    height: 100%;
    margin-right: 40px;
    box-shadow: 19px 4px 20px 0 rgba(0, 0, 0, 0.3);
    background-color: #262626;
    flex-shrink: 0;
}
.video__list {
    flex: 1;
}
