.business {
    height: 50px;
    padding: 0 32px;
    :global {
        .swiper-button-disabled {
            span {
                color: #e5e5e5;
            }
            &.swiper-button-prev,
            &.swiper-button-next {
                &::after {
                    display: none;
                }
            }
        }
    }
}
.business__item {
    flex-shrink: 0;
    width: auto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
    &:hover {
        color: #2c36de;
    }
}
.business__item.active {
    color: #2c36de;
    font-weight: 500;
    font-family: PingFangSC-Medium;
}

// -------------------------- 左右按钮 Start --------------------------
.swiperBtnPrev,
.swiperBtnNext {
    width: 32px;
    height: 100%;
    top: 22px;
    box-sizing: border-box;
    text-align: center;
    background-color: var(--invalid-name);
    opacity: 1 !important;
    position: absolute;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 60px;
        height: 100%;
        pointer-events: none;
    }
}
.swiperBtnPrev::after {
    background-image: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
    left: 32px;
}
.swiperBtnNext::after {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 100%);
    right: 32px;
}

.swiperBtnPrev {
    left: 0;
}

.swiperBtnNext {
    right: 0;
}
.button {
    color: #999;
    font-size: 24px;
    line-height: 50px;
}

// -------------------------- 左右按钮 End --------------------------
