.flashNewsList {
    margin-bottom: 32px;
}

.flashNewsDate {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 16px;
    color: var(--pc-visited-color);
    font-size: var(--pc-font-size-16);
    line-height: normal;
}

.flashNewsItem {
    position: relative;
    display: flex;
    box-sizing: border-box;
    margin-bottom: 24px;
    padding: 24px;
    background-color: var(--pc-base-background);
    border-radius: 4px;

    & > a {
        display: flex;
        justify-content: space-between;
        color: var(--pc-normal-color);
    }

    .flashNewsTimer {
        width: 40px;
        padding-right: 24px;
        color: var(--pc-normal-color);
        font-size: var(--pc-font-size-16);
        white-space: pre;
    }

    .flashNewsCon {
        flex: 1;
        overflow: hidden;

        .flashNewsTitle {
            display: block;
            width: 100%;
            margin-bottom: 8px;
            overflow: hidden;
            color: var(--pc-normal-color);
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: var(--pc-font-size-18);
            line-height: normal;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;

            &:hover {
                color: var(--pc-primary-color);
            }
        }

        .flashNewsIntrod {
            display: -webkit-box;
            overflow: hidden;
            color: var(--pc-visited-color);
            font-size: var(--pc-font-size-16);
            line-height: 150%;
            white-space: pre-wrap;
            text-overflow: ellipsis;
            word-break: normal;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .sourceUrl {
            display: flex;
            justify-content: flex-start;

            a {
                color: #1e80ff;
            }
        }

        .flashNewsMsg {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 28px;
        }

        .flashNewsTag {
            position: relative;
            top: 1px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            line-height: 16px;

            a {
                margin-right: 8px;
                color: var(--pc-base-f1-color);
                font-size: var(--pc-font-size-14);
                white-space: pre;

                &:hover {
                    color: var(--pc-primary-color);
                }
            }
        }

        .flashNewsIcon {
            display: flex;
            align-items: center;
        }
    }
}

@media screen and (max-width: 628px) {
    .flashNewsList {
        margin-bottom: 32px;
    }

    .flashNewsDate {
        padding: 0 16px;
    }

    .flashNewsItem {
        position: relative;
        margin-bottom: 0;
        padding: 16px;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--pc-line-background);
            content: '';
        }

        .flashNewsTimer {
            width: 40px;
            padding-top: 3px;
            padding-right: 8px;
            font-size: var(--pc-font-size-14);
        }

        .flashNewsCon {
            width: calc(100% - 48px);

            .flashNewsTitle {
                font-size: var(--pc-font-size-16);
            }

            .flashNewsIntrod {
                margin-bottom: 24px;
                font-size: var(--pc-font-size-14);
            }

            .flashNewsMsg {
                flex-wrap: wrap;
            }

            .flashNewsTag {
                a {
                    font-size: var(--pc-font-size-10);
                }
            }

            .flashNewsIcon {
                flex: 1;
                justify-content: flex-end;
            }
        }
    }
}
