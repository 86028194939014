@import '~@/styles/utils.less';

.swiperContain {
    width: 100%;
    height: 238px;
    border-radius: 4px;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;

    :global {
        .swiper-pagination {
            bottom: 12px;
            height: 2px;
        }

        .swiper-button-disabled {
            cursor: pointer;
            opacity: 1;
            pointer-events: auto;
        }
    }

    & > div {
        height: 100%;
    }

    .swiperBtnNext,
    .swiperBtnPrev {
        top: 50%;
        width: 38px;
        height: 38px;
        object-fit: contain;
        background-color: rgba(255, 255, 255, 0.1);
        // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        opacity: 0;
        border-radius: 50%;
        transition: opacity 0.8s;

        &::after {
            color: var(--pc-base-background);
            font-weight: 400;
            font-size: 12px;
        }
    }

    .swiperBtnNext {
        right: 16px;
    }

    .swiperBtnPrev {
        left: 16px;
    }

    &:hover {
        .swiperBtnNext,
        .swiperBtnPrev {
            opacity: 1;
            background-color: #fff;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
            &::after {
                color: #171617;
            }
        }
    }
}

.swiperCon {
    overflow: hidden;
    border-radius: 4px;

    :global {
        .swiper-pagination-horizontal {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.swiperSlideBox {
    position: relative;
    width: 100%;
    height: 100%;
}

.playBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translateX(-50%) translateY(-50%);
}

.swiperSlide {
    box-sizing: border-box;
    height: 100%;
}

.swiperBullet {
    display: inline-block;
    width: 28px;
    height: 2px;
    margin-right: 4px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }
}

.swiperBulletActive {
    position: relative;
    overflow: hidden;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #fff;
        border-radius: 1px;
        animation: progressMove linear 5s 1 forwards;
        content: '';
    }
}

.swiperSlideTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    height: 50%;
    color: var(--pc-base-background);
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-size: 16px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000);
    cursor: pointer;
    transition: max-height 0.5s;

    .swiperSlideTitleText {
        position: absolute;
        bottom: 26px;
        left: 0;
        display: -webkit-box;
        box-sizing: border-box;
        position:;
        width: 100%;
        line-height: 140%;
        letter-spacing: 0.2px;
        padding: 0 12px;
        .maxLine(2);
    }

    .swiperSlideTitleOne {
        line-height: 150%;
        bottom: 12px;
    }
}

@keyframes progressMove {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
