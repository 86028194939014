// ---------------------------- 导航交易商榜单样式
.brokerNav {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;

    & > h2 {
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        span:first-child {
            display: inline-block;
            font-size: 24px;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            line-height: 32px;
            letter-spacing: 0.75px;
            color: #010b27;
        }

        a:last-child {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-left: 24px;
            background-size: 100% 100%;
            margin-top: 2px;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
}

.billboard {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    & > ul {
        width: calc((100% - 24px) / 2);
    }
}

.billboardOne {
    flex-wrap: wrap;

    & > ul {
        width: 100%;
    }
}

.brokerList {
    padding: 0;
    margin: 0;

    & > li {
        position: relative;
        margin-bottom: 24px;
        background-color: #ffffff;
    }
}

.brokeItem {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
        margin-bottom: 0;
    }

    .logo {
        width: 186px;
        height: 110px;
        border: solid 1px #ebebeb;
        margin-right: 16px;
        box-sizing: border-box;
        background-color: #ffffff;
        position: relative;

        .num {
            width: 24px;
            height: 29px;
            position: absolute;
            top: -3px;
            left: 0;
            text-align: center;
            color: #ffffff;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .info {
        width: calc(100% - 202px);
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h2 {
            font-size: 20px;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #010b27;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
            margin-bottom: 7px;
        }

        .msg {
            display: flex;
            align-items: flex-end;
            white-space: pre;

            .score {
                color: #262f2d;
                line-height: 1;
                margin: 0 16px 0 0;

                span {
                    font-size: 20px;
                    font-family: 'D-DIN';
                    letter-spacing: 0.5px;
                    color: #fe3434;
                    margin-left: -4px;
                }
            }

            .grade {
                height: 18px;
            }
        }

        .textBot {
            margin: 0;
            font-size: 14px;
            letter-spacing: 0.5px;
            color: #999999;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
            line-height: normal;

            & > span {
                position: relative;
                color: #999999;
                cursor: default;

                &:first-child {
                    margin-right: 16px;
                }

                .split {
                    margin: 0 4px;
                }
            }
        }
    }

    &:hover {
        .info {
            h2 {
                color: var(--pc-primary-color);
            }
        }
    }
}

.loadMore {
    margin: 0 auto;

    :global {
        .load-empty {
            padding: 24px 0;
            margin-top: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .brokerNav {
        :global {
            #tabs {
                .swiper {
                    padding: 0;
                }
            }
        }
    }

    .brokeItem {
        padding: 0;
    }
}

@media screen and (max-width: 628px) {
    .brokerNav {
        background-color: #ffffff;

        & > h2 {
            margin-bottom: 8px;
            padding: 0;
            justify-content: space-between;

            span:first-child {
                display: inline-block;
                line-height: 24px;
                font-size: 18px;
                letter-spacing: 0.56px;
            }

            a:last-child {
                width: 18px;
                height: 18px;
            }
        }
    }

    .billboard {
        margin-top: 8px;
    }

    .brokerList {
        & > li {
            margin-bottom: 0;
        }
    }

    .brokeItem {
        padding-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:last-child {
            margin-bottom: 0;
        }

        .logo {
            width: 120px;
            height: 90px;
            margin-right: 8px;
        }

        .info {
            width: calc(100% - 128px);
            height: 90px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h2 {
                font-size: 16px;
                letter-spacing: 0.5px;
                font-weight: normal;
                margin-bottom: 8px;
            }

            .msg {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .score {
                    margin: 0 0 5px 0;
                }

                .rate {
                    margin-bottom: 7px;
                }
            }

            .textBot {
                font-size: 12px;

                span {
                    position: relative;
                    color: #999999;
                    cursor: default;
                }
            }
        }
    }
}

@media screen and (max-width: 415px) {
    .brokerNav {
        & > h2 {
            padding: 0 24px;
        }

        :global {
            #tabs {
                .swiper {
                    padding: 0 24px;
                }
            }
        }
    }

    .brokerList {
        padding: 0 24px;
        box-sizing: border-box;

        li {
            padding-bottom: 24px;
        }

        &:last-child {
            & > li:last-child {
                padding-bottom: 0;
            }
        }
    }

    .brokeItem {
        padding: 0;
    }
}
