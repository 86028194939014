.partItem {
    .partName {
        height: 48px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        color: #010b27;
        line-height: 48px;
        border-right: 1px solid #e5e5e5;
        border-left: 1px solid #e5e5e5;
        background-color: #f5f5f5;

        span {
            padding: 0 16px;
            position: sticky;
            left: 0;
            z-index: 1;
        }
    }
}
