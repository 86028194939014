.analyst {
    padding: 24px 24px 40px;
    border-radius: 4px;
    background-color: var(--pc-base-background);
    position: relative;
}

.analystTabs {
    padding: 25px 0;
    box-sizing: border-box;
    background-color: var(--pc-base-background);
    position: relative;
    border-bottom: 1px solid #f2f2f4;
    z-index: 1;

    :global {

        .swiper-button-prev,
        .swiper-button-next {
            top: 55%;
            background-color: var(--pc-base-background);
        }
    }
}

.analystCon {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
}

.analystList {
    width: 650px;
    overflow-y: auto;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background: #ebebeb;
    }

    &::-webkit-scrollbar-track {
        background: #f7f7f8;
    }
}

.analystDetail {
    width: calc(100% - 630px);
    margin-left: 16px;
}

.botTip {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.6px;
    color: #999;
    padding-top: 24px;
    border-top: 1px solid #f2f2f4;
}

@media screen and (max-width: 1392px) {
    .analystList {
        width: 55%;
    }

    .analystDetail {
        width: 45%;
    }
}