@import url('~@/styles/utils.less');

.hot_news {
    padding: 24px 16px;
    overflow: hidden;
    // background-image: linear-gradient(to bottom, #ffeccf, #fff 12%, #fff 100%);
    border-radius: 6px;
    background-repeat: no-repeat;
    background-size: 100% 780px;
    background-position: center top;
}

.hot_list {
    margin: 0;
    padding: 0;

    li {
        width: 100%;

        &:hover {
            .title {
                color: var(--theme-color);
            }
        }
    }
}

.img_item {
    display: flex;
    padding: 16px 0;
}

.left_con {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 117px);
    padding-right: 12px;
}

.left_con .num {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
}

.left_con .num1 {
    background-image: linear-gradient(to bottom, #ff9f1a, #fe250d);
}

.left_con .num2 {
    background-image: linear-gradient(to bottom, #ffd717, #f25700);
}

.left_con .num3 {
    background-image: linear-gradient(to bottom, #ffeb82, #ff8000);
}

.left_con .text {
    color: #262626;
    font-size: 16px;
    line-height: 24px;
    .maxLine(2);
}

.left_con p {
    margin: 0;
    color: #666;
    line-height: 22px;
}

.cover {
    width: 117px;
    height: 78px;
    overflow: hidden;
    background-color: #e5e5e5;
    border-radius: 4px;
    position: relative;
}

.cover img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}

.text_item {
    display: flex;
    align-items: center;
    padding: 12px 0;
}

.text_item .num {
    display: inline-block;
    width: 20px;
    margin-right: 8px;
    color: #999;
    font-size: 18px;
    font-family: D-DIN, sans-serif;
    text-align: center;
}

.text_item .title {
    width: calc(100% - 26px);
    color: #262626;
    font-size: 16px;
    line-height: 24px;
    .maxLine(1);
}
