.list__wrapper {
    display: flex;
    flex-direction: column;
}
.filter__wrapper {
    background-color: #f5f5fa;
    position: sticky;
    z-index: 2;
    border-top: 16px solid #f5f5fa;
}
