.flex {
    display: flex;
    display: -webkit-flex;
}

.colBox {
    .flex;

    width: 100%;

    & > div {
        display: inline-block;
    }
}
