@import url('~@/styles/utils.less');
.topicBox {
    background-color: #fff;
    border-radius: 4px;
    padding-bottom: 24px;
}

// -------------------- 头部 Start --------------------
.top_box {
    z-index: 3;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 12px 12px 8px 12px;
}

.wrapper__more {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    cursor: pointer;
    color: #999;
    &:hover {
        color: #2c36de;
    }
    .text__more {
        font-weight: normal;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        display: flex;
        align-items: center;
        letter-spacing: normal;
    }
    .arrow__more {
        font-size: 16px;
        line-height: normal;
    }
}
.tab_wrapper {
    display: flex;
    padding: 0 12px 8px;
    gap: 24px;
    .tab_item {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: normal;
        text-align: left;
        color: var(--color-text-primary);
    }
    .tab_item.active {
        color: var(--theme-color);
    }
}
// -------------------- 头部 End --------------------
// -------------------- 轮播图 Start --------------------
.swiper_list {
    :global {
        .swiper-pagination {
            bottom: -6px;
            .swiper-pagination-bullet {
                width: 28px;
                height: 2px;
                border-radius: 0;
            }
        }
    }
    .swiper_box_item {
        overflow: hidden;
        width: 100%;
        padding: 0 12px;
        box-sizing: border-box;
    }
    .swiperSlide {
        padding-bottom: 26px;
    }
    .list_item {
        border-radius: 4px;
        background-image: linear-gradient(180deg, #e9f5ff, rgba(246, 246, 247, 0.1));
        width: 100%;
    }

    .swiperSlideBox {
        display: flex;
        gap: 12px;
        height: 104px;
        box-sizing: border-box;
        padding: 8px 8px 0;
        .img_con {
            width: 96px;
            height: 96px;
            position: relative;
            overflow: hidden;
            border-radius: 4px;
        }
    }

    .text_content {
        flex: 1;
    }
    .swiperSlideTitleText {
        .maxLine(1);
        font-size: 16px;
        line-height: 24px;
        color: var(--color-text-primary);
    }
    .introduction {
        .maxLine(2);
        height: 36px;
        margin-top: 4px;
        font-size: 12px;
        color: #666;
        line-height: 1.5;
    }
}
.focus {
    width: max-content;
    flex-shrink: 0;
    height: 26px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 4px 12px;
    margin-top: 6px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: normal;
    text-align: center;
    color: var(--color-text-primary);

    background-color: #f5f5f5;
    cursor: pointer;

    picture {
        width: 14px;
        height: 14px;
    }
    img {
        width: 14px;
        height: 14px;
    }
    span {
        flex-shrink: 0;
    }

    &.not {
        background-color: var(--theme-color);
        color: var(--invalid-name);
        padding: 4px 10px;
        &:hover {
            background-color: var(--btn-hover-color);
        }
    }
}
// -------------------- 轮播图 End --------------------
// -------------------- 稿件列表 Start --------------------
.article_list {
    height: 188px;
    padding: 10px 12px;
    box-sizing: border-box;
}
.article_item {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    &.line {
        &::after {
            content: '';
            background-color: #e5e5e5;
            height: 1px;
            width: 100%;
            display: inline-block;
            margin-top: 7px;
        }
    }
    &:first-child {
        margin-top: 0;
    }
}
.article_item_title {
    .maxLine(2);
    font-size: 14px;
    line-height: 22px;
    color: var(--color-text-primary);
    height: 44px;
}
// -------------------- 稿件列表 End --------------------
