.flashNewsTit {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    position: relative;
    margin-bottom: 24px;

    span {
        display: block;

        &:nth-of-type(1) {
            width: 48px;
            height: 24px;
            margin-right: 6px;
            color: var(--pc-base-background);
            font-size: var(--pc-font-size-14);
            font-style: oblique;
            background-color: var(--pc-base-f5-color);
            border-radius: 12px 12px 0 12px;
            text-align: center;
            line-height: 24px;
            user-select: none;
        }

        &:nth-of-type(2) {
            margin-right: 8px;
            font-size: 24px;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            line-height: 24px;
            padding-bottom: 2px;
            color: var(--pc-normal-color);
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 4px;
        background-color: var(--pc-normal-hover-color);
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 104px;
        height: 4px;
        background-color: #262f2d;
    }
}

.tabsCon {
    padding-top: 24px;
    min-height: 300px;
}

@media screen and (max-width: 628px) {
    .flashNewsTit {
        padding: 12px 0;
        margin-bottom: 0;

        span {
            &:nth-of-type(2) {
                font-size: 20px;
            }
        }
    }

    .tabsConBox,
    .tabsCon {
        padding-top: 0 !important;
    }
}
