@import '~@/styles/utils.less';

.slider__wrapper {
    padding: 16px;
}
.header {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    em {
        position: absolute;
        left: 0;
        top: 5px;
        width: 3px;
        height: 14px;
        border-radius: 2px;
        background-color: #2c36de;
    }
}
// 标题
.title {
    font-size: 16px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
}
.slider {
    :global {
        .ant-slider-dot-active {
            border-color: #2c36de !important;
        }

        .ant-slider-handle {
            width: 15px !important;
            height: 15px !important;

            &::after {
                width: 5px !important;
                height: 5px !important;
                top: 4px !important;
                left: 4px !important;
                box-shadow: 0 0 0 5px #2c36de !important;
            }
            &::before {
                width: 15px !important;
                height: 15px !important;
            }
        }
        .ant-slider-track {
            background-color: #2c36de !important;
        }
        .ant-slider-mark-text:nth-child(1) {
            left: -8px !important;
            transform: none !important;
        }
    }
}
