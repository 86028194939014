.analyst {
    position: relative;
    background-color: #f7f7f8;
    border-radius: 4px;
}

.analystTabs {
    position: relative;
    box-sizing: border-box;
    padding: 8px 16px;
    background-color: var(--pc-base-background);
}

.analystCon {
    display: flex;
    justify-content: space-between;
}

.analystList {
    width: 100%;
}

.botTip {
    margin-top: 24px;
    padding-top: 24px;
    color: #999;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.6px;
    border-top: 1px solid #f2f2f4;
}
