@import '~@/styles/utils.less';

.video {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 6px;
    background-color: var(--invalid-name);
}

// ----------------------------- 头部 Start -----------------------------
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header__left {
    position: relative;
    h2 {
        position: relative;
        font-size: 16px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }
}
.header__right {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #999;

    &:hover {
        color: #999;
    }
    &::after {
        margin-left: 4px;
        color: #999 !important;
        font-size: 12px;
        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
        font-family: swiper-icons;
        font-variant: normal;
        font-variant: initial;
        line-height: 1;
        letter-spacing: 0;
        text-align: center;
        text-transform: none !important;
        content: 'next';
    }
}
// ----------------------------- 头部 End -----------------------------
// ----------------------------- 列表 Start -----------------------------
.list {
    .list__item {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-bottom: 11px;
        margin-top: 10px;
        border-bottom: 1px solid #e5e5e5;
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
    .list__item__cover {
        width: 116px;
        height: 77px;
        border-radius: 4px;
        margin-right: 8px;
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
    }
    .cover__play {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
    }
    .cover__play__icon {
        width: 18px;
        height: 18px;
        position: relative;
    }
    .list__item__title {
        .maxLine(3);

        flex: 1;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: -0.41px;
        text-align: left;
        color: #262626;
    }
}
// ----------------------------- 列表 End -----------------------------
