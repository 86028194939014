.topicBox {
    background-color: #fff;
    border-radius: 6px;
    position: relative;
    z-index: 10;
}
.top_box {
    z-index: 3;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 24px 16px 8px 16px;
    .top_title {
        position: relative;
        font-size: 24px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        z-index: 10;
        line-height: 1.25;
    }
}
.swiper_con {
    display: flex;
    gap: 16px;
    padding: 16px 16px 0;
    .swiper_list {
        width: 447px;
        flex-shrink: 0;
    }
    .swiperSlideBox {
        cursor: pointer;
    }
    .img_con {
        height: 447px;
        position: relative;
        overflow: hidden;
        border-radius: 4px;
    }
    .text_content {
        margin-top: 16px;
        height: 131px;
    }
    .swiperSlideTitleText {
        font-size: 24px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        line-height: 1.5;
        letter-spacing: 0.12px;
        color: #262626;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
            color: #2c36de;
        }
    }
    .introduction {
        margin-top: 6px;
        font-size: 14px;
        color: #666;
        line-height: 1.57;
        letter-spacing: 0.07px;
        display: -webkit-box;
        word-break: break-all;
        overflow: hidden;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
    }
    .active_index_con {
        display: flex;
        margin-top: 20px;
        gap: 40px;
        margin-bottom: 32px;
    }
    .data_length {
        width: 60px;
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        line-height: 1.5;
        letter-spacing: 0.08px;
        border: solid 1px #e5e5e5;
    }
}
.wrapper__more {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    cursor: pointer;
    color: #999;
    &:hover {
        color: #2c36de;
    }
    .text__more {
        font-weight: normal;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        display: flex;
        align-items: center;
        letter-spacing: normal;
    }
    .arrow__more {
        font-size: 18px;
        line-height: normal;
    }
}

.wrapper__title {
    position: relative;
    flex-shrink: 0;
    h2 {
        position: relative;
        z-index: 1;
        color: #000;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 24px;
        font-style: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: normal;
    }

    .top_bg_icon {
        position: absolute;
        top: -10px;
        background-size: 23px 21px;
        left: -10px;
        width: 23px;
        height: 21px;
    }
}

.img_con:hover {
    img {
        transform: scale(1.1);
        transition: all 0.5s;
    }
}
