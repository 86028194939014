.keyWords {
    // width: 100%;
    height: 40px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;

    :global {
        .swiper-button-disabled {
            opacity: 1;

            &>div,
            &::after {
                opacity: 0.35;
            }
        }
    }
}

.keyWordsHasBtn {
    padding: 0 48px;
}

.keyWordItem {
    width: auto;
    line-height: 40px;
    display: inline-block;
    padding: 0 20px;
    cursor: pointer;
    white-space: nowrap;
    letter-spacing: 1.5px;

    a {
        font-size: var(--pc-font-size-18);
        color: var(--pc-base-f9-color);
    }

    &:hover {
        a {
            color: var(--pc-primary-color);
        }
    }

    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 50%;
        position: absolute;
        top: 52%;
        right: 0;
        transform: translateY(-50%) rotateZ(30deg);
        background-color: var(--pc-base-f1-color);
    }

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;

        &::after {
            display: none;
        }
    }

    &.swiper-slide-active:first-child {
        padding-left: 48px;
    }

    &.swiper-slide-active:last-child {
        padding-right: 48px;
    }
}

.swiperBackPrev,
.swiperBackNext {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background-color: var(--pc-base-background);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.swiperBackPrev {
    left: 2px;
}

.swiperBackNext {
    right: 2px;
}

.swiperBtnPrev,
.swiperBtnNext {
    width: 48px;
    height: 36px;
    position: absolute;
    top: 60%;
    z-index: 9;
    background-color: var(--pc-line-background);

    &::after {
        font-size: 14px;
        color: var(--pc-normal-color);
        z-index: 2;
    }
}

.swiperBtnPrev {
    left: 0;


    &::after {
        margin-right: 10px;
    }
}

.swiperBtnNext {
    right: 0;

    &::after {
        margin-left: 10px;
    }
}

@media screen and (max-width: 998px) {
    .keyWordItem {
        a {
            font-size: var(--pc-font-size-16);
        }
    }
}

@media screen and (max-width: 628px) {
    .keyWordBox {
        display: none;
    }
}