@import '~@/styles/utils.less';

.wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 24px 16px 40px;
    background-color: #ffffff;
    border-radius: 6px;
}

.contrast_top {
    margin-bottom: 24px;
}

.contrast_list {
    width: 100%;
    padding: 0;
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: repeat(3, calc((100% - 32px) / 3));

    li {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100px;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
    }

    li.sel_broker {
        padding: 0 16px;
    }

    li.sel_broker > div {
        width: 100%;
        position: relative;
    }

    li a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 16px 0;
        box-sizing: border-box;

        padding: 16px;
    }

    li .logo {
        position: relative;
        box-sizing: border-box;
        width: 68px;
        height: 68px;
        margin-right: 12px;
        overflow: hidden;
        background-color: #ffffff;
        border: solid 1px #e5e5e5;
        border-radius: 4px;
        overflow: hidden;
    }

    li .logo img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    li .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 80px);
        height: 68px;
    }

    li .info p {
        width: 100%;
        margin: 0;
    }

    li .info .name {
        display: flex;
        align-items: center;
        position: relative;

        &:hover {
            & > span:first-child {
                color: var(--theme-color);
            }
        }

        & > span:first-child {
            width: calc(100% - 86px);
            color: #333;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: 18px;
            line-height: 1.44;
            .maxLine(1);
            word-break: break-all;
        }
    }

    li .info .name .change {
        position: absolute;
        right: 0;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);

        span {
            color: #999;
            font-size: 14px;
            line-height: 1.57;
            text-align: right;
        }

        span:first-child {
            display: block;
        }

        span:last-child {
            display: none;
            color: var(--theme-color);
        }

        &:hover {
            span:first-child {
                display: none;
            }

            span:last-child {
                display: block;
            }
        }
    }

    li .info .score {
        display: flex;
        align-items: center;

        span:first-child {
            position: relative;
            display: inline-block;
            margin-right: 8px;
            padding: 0 1px;
            color: #262626;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: 24px;
            line-height: 1;

            strong {
                position: relative;
                z-index: 1;
                font-family: D-DIN, sans-serif;
            }

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                // z-index: 1;
                width: 100%;
                height: 6px;
                background-color: #ffa216;
                border-radius: 3px;
                content: '';
            }
        }

        span:last-child {
            color: #999;
            font-size: 14px;
            line-height: 1.57;
        }
    }

    li .hide {
        display: none;
    }

    li .sel_box {
        height: 42px;
    }
}

.to_contrast {
    width: 475px;
    margin: 40px auto 16px;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        color: #fff;
        font-size: 16px;
        line-height: 1.5;
        background-color: #2c36de;
        border-radius: 4px;
        cursor: pointer;
    }

    span.btn_disabled {
        cursor: not-allowed;
        background-color: #adbaff;
    }
}

.clear_list {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    color: #999;
    font-size: 14px;
    line-height: 1;

    .clear_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            color: var(--theme-color);
        }
    }

    .clear_btn_dis {
        &:hover {
            color: #999;
        }
    }

    .clear_text {
        margin-left: 4px;
    }
}
