@import '~@/styles/utils.less';

.banner_con {
    position: relative;
    display: block;
    width: 100%;
    height: 150px;
    overflow: hidden;

    .banenr_mask {
        position: absolute;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
    }

    .banenr_tip {
        position: absolute;
        bottom: 12px;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        padding: 0 12px;
        overflow: hidden;
        color: #ffffff;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        .maxLine(1);
        word-break: break-all;

        a {
            color: #ffffff;
        }
    }
}
