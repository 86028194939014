.brokerMenu {
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100vw;
    // height: 100vh;
    opacity: 0;
    box-sizing: border-box;
    background-color: var(--pc-base-background);
    z-index: 1000;
    transition: all 0.2s;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: var(--pc-normal-hover-color);
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
}

.brokerMenuOpen {
    opacity: 1;
}

.searchInputBox {
    padding: 16px 24px;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1;

    .searchInput {
        padding: 0 24px;
        height: 40px;
        border-radius: 20px;
        border: solid 1px #ebebeb;
        background-color: #f7f8fb;
        caret-color: #00e0b9;
        box-sizing: border-box;

        input {
            height: 16px;
            margin: 11px 0;
            background-color: transparent;
        }

        .prefix {
            color: #999999;
        }

        :global {
            .ant-input-prefix {
                margin-right: 8px;
            }

            .ant-input-affix-wrapper {
                padding: 0;
            }
        }

        .closeIcon {
            cursor: pointer;
            width: 16px;
            height: 16px;
            display: block;
            font-size: 16px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #cccccc;
            position: relative;
            top: 1px;
        }
    }
}

.brokerMenuList {
    width: 100%;
    padding: 0 24px 24px;
    box-sizing: border-box;

    .selOption {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        .logo {
            width: 28px;
            height: 28px;
            margin-right: 8px;
            border-radius: 14px;
            border: 1px solid #ebebeb;
            overflow: hidden;
            box-sizing: border-box;
            position: relative;
        }

        .text {
            line-height: 1.5;
            letter-spacing: 0.5px;
            color: #262f2d;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
        }

        .business__name {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.5px;
            text-align: right;
            color: #00c6a4;
        }
    }
}

.nullData {
    width: 200px;
    margin: 40px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .nullTxt {
        margin-top: 16px;
        font-size: 12px;
        color: #666666;
    }
}