@import '~@/styles/utils.less';

.wrapper {
    padding-right: 16px;
}

.recommend_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.recommend_swiper_item {
    margin-top: 24px;
    height: 372px;
}

.recommend_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    margin-bottom: 0;
    padding: 0;
}

.recommend_item {
    height: 124px;
    position: relative;
    cursor: pointer;

    &.no_bottom,
    &:last-child {
        &::after {
            display: none;
        }
    }

    &:hover {
        background-color: #f7f7f8;
    }
}

.no_more {
    width: 100%;
}

.null_data {
    height: 100%;
}
