.wrapper {
    margin-top: 12px;
    background-color: var(--invalid-name);
    border-radius: 6px;
}
// ----------------------- 头部 Start -----------------------
.tool_top {
    z-index: 3;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 12px 12px 0;
}
.wrapper__more {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    color: #999;
    cursor: pointer;
    &:hover {
        color: #2c36de;
    }
    .text__more {
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
    }
    .arrow__more {
        font-size: 18px;
        line-height: normal;
    }
}
// ----------------------- 头部 End -----------------------
.tool_wrapper {
    display: flex;
    flex-direction: column;
}
.tabs {
    display: flex;
    flex-shrink: 0;
    gap: 24px;
    box-sizing: border-box;
    padding: 8px 12px 12px;
    width: 100%;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    span {
        flex-shrink: 0;
        font-size: 14px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        letter-spacing: normal;
        color: var(--color-text-primary);
        &.active {
            color: var(--theme-color);
        }
    }
}

.tool {
    flex: 1;
    width: 100% !important;
    min-height: 250px;
    padding: 12px;
    box-sizing: border-box;
    a {
        display: none;
    }
}
