@import '~@/styles/utils.less';

@header-height: 30px;

.wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 0 0;
    background-color: #fff;
    border-radius: 6px;
}

.top {
    display: flex;
    align-items: center;
    width: 100%;
    height: @header-height;
    padding: 0 16px;
    box-sizing: border-box;
    position: relative;
}

.topTitle {
    margin-right: 40px;
}

.maxLine {
    max-width: 332px;
    .maxLine(1);
    word-break: break-all;
}

.list {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    position: relative;
    padding-right: 80px;
    box-sizing: border-box;
}

.list_item {
    width: auto !important;
    padding: 0 12px;
    color: #262626;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    max-width: 177px;
    line-height: 30px;
    .maxLine(1);
    word-break: break-all;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    &:hover,
    &.active {
        color: var(--theme-color);
    }

    &.active {
        font-weight: 500;
        font-family: PingFangSC-Medium;
    }
}

.more {
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 152px;
    position: absolute;
    right: 16px;
    z-index: 1;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 61%);

    .more_con {
        color: #999;
        font-size: 14px;
        display: flex;
        align-items: center;
        height: 100%;
        background-color: #fff;
        cursor: pointer;
        pointer-events: auto;
        box-sizing: border-box;

        &:hover {
            color: var(--theme-color);
        }
    }

    .arrow {
        font-size: 14px;
    }

    &.moreLast {
        background-image: none;
    }
}

.topLine {
    height: 30px;
    margin-bottom: 0;

    .list {
        box-sizing: border-box;
        height: 100%;
    }

    .list .list_item {
        box-sizing: border-box;
        padding-bottom: 4px;
        line-height: 30px;
    }

    .more {
        .more_con {
            height: 30px;
        }
    }
}

.details_list_box {
    display: flex;
    margin-top: 16px;
    padding-bottom: 24px;
}

.details_list {
    width: 50%;
    padding: 0;

    li {
        position: relative;

        padding: 24px 16px;
        cursor: pointer;
        border-radius: 6px;

        &:hover {
            background-color: #f7f7f8;
        }

        &.no_bottom::after {
            display: none;
        }
    }
}

.no_more {
    padding-bottom: 40px;
}

.null_data {
    flex: 1;
    min-height: 500px;
    display: flex;
    align-items: center;
}
