@import '~@/styles/utils.less';

.preview {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
// ---------------------- 标题 Start ----------------------
.video__title {
    position: relative;
    margin-top: 60px;
    flex-shrink: 0;
    h2 {
        position: relative;
        z-index: 1;
        font-size: 24px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        .maxLine(1);
    }

    .video__title__icon {
        position: absolute;
        background-size: 23px 21px;
        top: -10px;
        left: -10px;
        width: 23px;
        height: 21px;
    }
}
// ---------------------- 标题 End ----------------------
// ---------------------- 视频内容 Start ----------------------
.video__content {
    margin-top: 38px;
    width: 100%;
    height: 514px;
    overflow: hidden;
    flex-shrink: 0;
    border-radius: 4px;
    cursor: pointer;

    video {
        height: 100%;
        width: 100%;
        background: #000;
        object-fit: contain;
        transition: all 0.5s;
    }
    &:hover {
        :global {
            :not(.vjs-has-started) {
                video {
                    transform: scale(1.1);
                }
            }
        }
    }
    :global {
        .video-js {
            border-radius: 0;
            padding-top: calc(66% + 4px) !important;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                z-index: 1;
                pointer-events: none;
            }
        }
        .vjs-has-started {
            video {
                transition: none;
            }
            &::before {
                display: none;
            }
        }
    }
}
// ---------------------- 视频内容 End ----------------------
// ---------------------- 视频信息 Start ----------------------
.video__footer {
    margin-top: 16px;
}
.video__footer__title {
    .maxLine(3);
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
    cursor: pointer;
    &:hover {
        color: #fff;
        text-decoration: underline;
    }
}
.video__footer__time {
    margin-top: 8px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.5);
}

// ---------------------- 视频信息 End ----------------------
