.wrapper {
    padding: 16px;
    width: 100vw;
    background-color: var(--invalid-name);
    overflow: auto;
    margin-bottom: 12px;
    margin-left: -12px;
    box-sizing: border-box;
    &::-webkit-scrollbar {
        display: none;
    }
}
// ---------------------  重置 Start ---------------------
.reset__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.reset {
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: #2c36de;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;

    span:nth-child(1) {
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
    }
    span:nth-child(2) {
        font-size: 16px;
        margin-left: 4px;
    }
}
.reset__wrapper .reset {
    margin-right: 12px;
}
.reset__wrapper .reset:last-child {
    margin-right: 0;
}
.reset__wrapper .reset.active {
    background-color: #2c36de;
    color: var(--invalid-name);
}
.reset__wrapper .reset.active:hover {
}
// ---------------------  重置 End ---------------------
// ---------------------  已选筛选项 Start ---------------------
.selected {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
.selected__wrapper {
    max-height: 114px;
    overflow: hidden;
}
.selected__wrapper.showMore {
    max-height: none;
}

.selected__value {
    padding: 4px 12px 4px 16px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f8;
    box-sizing: border-box;
    height: 30px;
    .text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
    }
    .icon {
        color: #999899;
        font-size: 20px;
        line-height: 22px;
        margin-left: 4px;
        cursor: pointer;
    }
}
// ---------------------  已选筛选项 End ---------------------
.more {
    margin: 16px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #999;
    }
    span:nth-child(2) {
        margin-left: 4px;
        font-size: 14px;
        transform: rotate(90deg);
    }
}
.more.active span:nth-child(2) {
    transform: rotate(-90deg);
}
