.list {
    padding-bottom: 24px;
}
.listRow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
    .listCol {
        width: calc(50% - 16px);
    }
}
// ------------- 没有更多 Start ------------- //
.wrapper__noMore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
    em {
        width: 213px;
        height: 1px;
        background-color: #e5e5e5;
    }
    span {
        padding: 0 12px;
        color: #999;
        font-weight: normal;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 22px;
        letter-spacing: 0.07px;
    }
}
// ------------- 没有更多 End ------------- //
