.container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}
.containerLf {
  flex: 1;
  width: 100%;
}
.containerLf.hidden {
  overflow: hidden;
}
.containerRf {
  width: 278px;
  margin-left: 24px;
}
.containerNoAuth {
  font-size: 1px;
}
.containerNoAuth :glboal .ant-affix {
  z-index: 1000;
}
.marginTop {
  margin-top: 16px;
}
@media screen and (max-width: 1439px) {
  .containerRf {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .marginTop {
    margin-top: 12px;
  }
}
