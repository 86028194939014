@import '~@/styles/utils.less';

@left-width: 7px;

.wrapper {
    padding: 12px 0;
    margin-top: 12px;
}

.contrast_top {
    padding: 0 12px;
}

.contrast_list {
    margin-top: 12px;
    display: grid;
    grid-column-gap: 8px;
    grid-template-columns: repeat(3, 1fr);

    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 16px 12px;
        min-height: 156px;
        position: relative;
        background-color: #fff;
        border-radius: 4px;
        box-sizing: border-box;
    }

    li.sel_broker {
        padding: 0 16px;
    }

    li a {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    li .logo {
        position: relative;
        box-sizing: border-box;
        width: 44px;
        height: 44px;
        border-radius: 22px;
        overflow: hidden;
        background-color: #ffffff;
        border: solid 1px #e5e5e5;
    }

    li .logo img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    li p {
        width: 100%;
        margin: 0;
    }

    li .name {
        line-height: 1.57;
        text-align: center;
        color: #000;
        margin-top: 8px;
        .maxLine(1);
        word-break: break-all;
    }

    li .score {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        span:first-child {
            position: relative;
            display: inline-block;
            margin-right: 8px;
            padding: 0 1px;
            color: #262626;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: 16px;
            line-height: 1;

            strong {
                position: relative;
                z-index: 1;
                font-family: D-DIN, sans-serif;
            }

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background-color: #ffa216;
                border-radius: 2px;
                content: '';
            }
        }

        span:last-child {
            color: #999;
            font-size: 12px;
            line-height: 1.5;
        }
    }

    li .change {
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: -0.41px;
        margin-top: 8px;
        color: var(--theme-color);
        text-align: center;
    }

    li .add_btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    li .add_btn .add_icon {
        font-size: 14px;
        color: #666;
    }

    li .add_btn .add_text {
        font-size: 12px;
        color: #666;
        line-height: 1.5;
        letter-spacing: -0.41px;
        margin-top: 8px;
    }

    li .hide {
        display: none;
    }
}

.to_contrast {
    margin: 12px 0 8px;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 34px;
        color: #fff;
        line-height: 1.57;
        background-color: var(--theme-color);
        border-radius: 4px;
        cursor: pointer;
    }

    span.btn_disabled {
        cursor: not-allowed;
        background-color: #adbaff;
    }
}

.clear_list {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    color: #999;
    font-size: 12px;
    line-height: 1;

    .clear_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .clear_text {
        margin-left: 4px;
    }
}
