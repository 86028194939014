.wrapper {
    width: 100%;
    height: 275px;
    border-radius: 6px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 16px;
}

.banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.search {
    display: flex;
    align-items: center;
    position: relative;
}

.search .input_box {
    width: 596px;
    height: 48px;
    border-radius: 4px;
    margin-right: 16px;
    // background-color: #fff;
    position: relative;

    :global {
        .ant-select .ant-select-selector {
            border-color: #fff !important;
        }

        .ant-select-focused {
            .ant-select-selector {
                box-shadow: none !important;
            }
        }
    }
}

.search .btn {
    min-width: 112px;
    height: 48px;
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
    border: none;
    background: var(--theme-color);

    &:hover,
    &:focus {
        color: #fff !important;
    }

    &:hover {
        background: var(--btn-hover-color) !important;
    }

    &:focus {
        background: var(--btn-click-color) !important;
    }
}
