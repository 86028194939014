@import '~@/styles/utils.less';

.filter {
    display: flex;
    margin-bottom: 8px;
    height: 40px;
    width: 100vw;
    margin-left: -12px;
    padding-top: 8px;
    position: sticky;
    background-color: #f5f5fa;
    z-index: 100;
}
.filter__item {
    display: flex;
    flex-shrink: 0;
    height: 40px;
    padding: 0 16px;
    box-sizing: border-box;

    // 状态筛选项
    &:nth-child(1) {
        width: 28.8%;
    }
    // 区域筛选项
    &:nth-child(2) {
        width: 29.6%;
    }

    &:nth-child(3) {
        width: 41.6%;
    }
}
.active.filter__item {
    background-color: var(--invalid-name);
    border-radius: 4px 4px 0 0;
    .label {
        em {
            transform: rotate(180deg); /* 旋转180度，使其倒置 */
        }
    }
    .list {
        display: flex;
    }
}

.label {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        letter-spacing: normal;
        text-align: left;
        color: #666;
        .maxLine(1);
    }
    em {
        position: relative;
        display: inline-block;
        margin-left: 4px;
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 4px solid #000; /* 控制三角形的大小和颜色 */
    }
}
.list {
    position: absolute;
    top: 48px;
    left: 0;
    z-index: 100;
    width: 100%;
    display: none;
    max-height: 320px;
    overflow-y: scroll;
    flex-direction: column;
    padding-bottom: 16px;
    border-radius: 0 0 4px 4px;
    background-color: var(--invalid-name);
}

.value {
    padding: 8px 24px;
    display: flex;
    height: 40px;
    box-sizing: border-box;
    justify-content: space-between;
    span:nth-child(1) {
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        letter-spacing: normal;
        color: #262626;
    }
    span:nth-child(2) {
        font-size: 16px;
        color: #2c36de;
        display: none;
    }
}
.active.value {
    span:nth-child(1) {
        font-weight: 500;
        font-family: PingFangSC-Medium;
        color: #2c36de;
    }
    span:nth-child(2) {
        display: inline-block;
    }
}

// ------------------ 蒙层 Start  ------------------
.mask {
    top: 0;
    left: 0;
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    // pointer-events: none;
}
// ------------------ 蒙层 End  ------------------
