// ---------------------------- 推荐交易商样式
.swiperContainer {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;

    & > h2 {
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        span:first-child {
            display: inline-block;
            font-size: 24px;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            line-height: 32px;
            letter-spacing: 0.75px;
            color: #010b27;
        }

        a:last-child {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-left: 24px;
            background-size: 100% 100%;
            margin-top: 2px;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    & > div {
        height: 100%;
        z-index: 0;
    }

    :global {
        .swiper {
            overflow: visible;
        }

        .swiper-container {
            overflow: inherit;
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: 35px;
            height: 35px;
            top: 50%;
            border-radius: 18px;
            background-color: rgba(153, 153, 153, 0.7);

            &::after {
                font-size: 10px;
                color: #010b27;
            }
        }

        .swiper-button-disabled {
            opacity: 1;
            cursor: pointer;
            pointer-events: auto;
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 0;
        }
    }

    .swiperBg {
        width: 100%;
        height: 107px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .swiperBg0 {
        background-image: linear-gradient(110deg, #e1d2d1 2%, #edd2d2 93%);
    }

    .swiperBg1 {
        background-image: linear-gradient(110deg, #e0dbe5 2%, #e4d3e0 93%);
    }

    .swiperBg2 {
        background-image: linear-gradient(110deg, #d6d2cd 2%, #e8dbd7 93%);
    }

    .swiperBg3 {
        background-image: linear-gradient(110deg, #d9e1e8 2%, #d4dce3 93%);
    }
}

.swiperConForPc {
    height: 224px;

    .swiperSlide {
        height: 100%;
    }

    .swiperSlideCon {
        height: 100%;
        position: relative;
        padding: 24px;
        overflow: hidden;
        border-radius: 4px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
        box-sizing: border-box;
    }

    .swiperSlideBox {
        display: block;
        position: relative;
        z-index: 1;
        height: 176px;
        background-color: #ffffff;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
        padding: 16px 16px 24px;
        user-select: none;

        .swiperSlideBoxTop {
            display: flex;
            align-items: flex-start;
        }

        .logoForRecommend {
            width: 64px;
            height: 64px;
            object-fit: contain !important;
            border-radius: 32px;
            margin-right: 16px;
            overflow: hidden;
            border: solid 1px #d8d8d8;
            box-sizing: border-box;
            position: relative;
        }

        .swiperSlideBoxInfo {
            width: calc(100% - 80px);
            letter-spacing: 0.5px;

            & > h2 {
                font-size: 18px;
                line-height: 150%;
                color: #262f2d;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: pre;
                margin-bottom: 4.5px;
                font-weight: normal;
            }

            .score {
                color: #262f2d;
                line-height: 1;
                margin-bottom: 8px;
                overflow: hidden;

                span:last-child {
                    font-size: 20px;
                    font-family: 'D-DIN';
                    letter-spacing: 0.5px;
                    color: #fe3434;
                    line-height: 1;
                }
            }

            .rate {
                font-size: 12px;
                color: #ff8502;

                :global {
                    .ant-rate-star {
                        margin-right: 4px;
                        height: 16px;
                        line-height: 16px;

                        .anticon {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .swiperSlideBoxBot {
            margin-top: 24px;
            white-space: pre-wrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            letter-spacing: 0.5px;
            line-height: normal;
            color: #999999;
        }

        &:hover {
            .swiperSlideBoxInfo {
                h2 {
                    color: var(--pc-primary-color);
                }
            }
        }
    }
}

.swiperConForMob {
    display: none;
    width: calc(100% + 24px);
    min-height: 462px;
    position: relative;
    left: -12px;
    padding: 0 12px;
    box-sizing: border-box;

    .swiperSlideList {
        display: grid;
        grid-template-columns: repeat(2, calc((100% - 16px) / 2));
        grid-column-gap: 16px;
        grid-row-gap: 16px;
    }

    .swiperSlideItem {
        padding: 16px;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
    }

    .swiperSlideBox {
        display: block;
        position: relative;
        z-index: 1;
        height: 191px;
        background-color: #ffffff;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
        padding: 12px 8px 16px;
        user-select: none;

        .swiperSlideBoxTop {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .logoForRecommend {
            width: 45px;
            height: 45px;
            object-fit: contain !important;
            border-radius: 32px;
            margin-right: 16px;
            overflow: hidden;
            border: solid 1px #d8d8d8;
            box-sizing: border-box;
            position: relative;
        }

        .swiperSlideBoxInfo {
            width: 100%;
            letter-spacing: 0.5px;
            margin-top: 4px;
            text-align: center;

            & > h2 {
                font-size: 16px;
                line-height: 1;
                color: #262f2d;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: pre;
                margin-bottom: 4px;
                font-weight: normal;
            }

            .score {
                color: #262f2d;
                line-height: 1;
                margin-bottom: 8px;
                overflow: hidden;
                font-size: 12px;

                span:last-child {
                    font-size: 16px;
                    font-family: 'D-DIN';
                    letter-spacing: 0.5px;
                    color: #fe3434;
                    line-height: 1;
                }
            }

            .grade {
                height: 10px;
                line-height: 10px;
                justify-content: center;
            }

            .rate {
                font-size: 12px;
                color: #ff8502;

                :global {
                    .ant-rate-star {
                        margin-right: 4px;
                        height: 16px;
                        line-height: 16px;

                        .anticon {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .swiperSlideBoxBot {
            margin-top: 12px;
            white-space: pre-wrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            line-height: normal;
            font-size: 10px;
            letter-spacing: 0.36px;
            color: #999999;
        }

        &:hover {
            .swiperSlideBoxInfo {
                h2 {
                    color: var(--pc-primary-color);
                }
            }
        }
    }
}

.loadMore {
    margin: 0 auto;

    :global {
        .load-empty {
            padding: 24px 0;
            margin-top: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .swiperContainer {
        padding: 24px 0 0;
    }
}

@media screen and (max-width: 628px) {
    .swiperContainer {
        & > h2 {
            justify-content: space-between;
        }

        .swiperConForPc {
            display: none;
        }

        .swiperConForMob {
            display: block;
        }

        .swiperSlide {
            height: calc((100% - 16px) / 2);
        }
    }
}

@media screen and (max-width: 415px) {
    .swiperContainer {
        padding: 0 24px;

        & > h2 {
            span:first-child {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.56px;
                color: #010b27;
            }

            a:last-child {
                width: 18px;
                height: 18px;
            }
        }
    }
}
