.active_con {
    background-color: #fff;
    margin-top: 12px;
    border-radius: 6px;
    position: relative;
    z-index: 10;
    .top_box {
        z-index: 3;
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 12px 12px 10px 12px;
        .top_title {
            position: relative;
            font-size: 16px;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-stretch: normal;
            font-style: normal;
            line-height: 24px;
            letter-spacing: normal;
            text-align: left;
            color: #000;
        }
    }
    .wrapper__more {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        cursor: pointer;
        color: #999;
        &:hover {
            color: #2c36de;
        }
        .text__more {
            font-weight: normal;
            font-size: 12px;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            display: flex;
            align-items: center;
            letter-spacing: normal;
        }
        .arrow__more {
            font-size: 16px;
            line-height: normal;
        }
    }
}
