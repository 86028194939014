.contactItem {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    word-wrap: break-word;
    word-break: normal;

    &:last-child {
        margin-bottom: 0;
    }

    .right {
        width: calc(100% - 24px);
        padding-left: 4px;
        padding-top: 2px;
        letter-spacing: 0.5px;
        color: #262f2d;
        line-height: normal;

        a {
            color: #262f2d;

            &:hover {
                color: var(--theme-color);
            }
        }
    }
}
