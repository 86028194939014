@import '~@/styles/utils.less';

.ugc {
    padding-top: 24px;
    border-radius: 6px;
    background-color: var(--invalid-name);
    box-sizing: border-box;
}
.ugc__header {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
}

// ----------------------------- 轮播图 Start -----------------------------
.ugc__swiper {
    margin-top: 8px;
    cursor: pointer;
}
// ----------------------------- 轮播图 End -----------------------------
// ----------------------------- 列表项 Start -----------------------------
.list__item {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
}
.item__wrapper {
    width: calc(25% - 12px);
    height: 351px;
    padding: 24px 16px;
    box-sizing: border-box;
}
// 头部
.header {
    display: flex;
    height: 74px;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #e5e5e5;
}
.avatar {
    flex-shrink: 0;
    position: relative;
    width: 62px;
    overflow: hidden;
    height: 62px;
    flex-shrink: 0;
    border-radius: 62px;
    margin-right: 12px;
}
.info {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .name__wrapper {
        display: flex;
        flex-shrink: 0;
        align-items: flex-start;
    }
    .name {
        .maxLine(1);
        margin-right: 4px;
        flex: 1;
        font-size: 18px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-stretch: normal;
        font-style: normal;
        line-height: 26px;
        letter-spacing: normal;
        color: #262626;
        &:hover {
            color: #2c36de;
        }
    }
    .focus {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        position: relative;
    }
    .intro {
        .maxLine(2);

        flex: 1;
        margin-top: 4px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        letter-spacing: normal;
        color: #666;
    }
}
// 稿件列表
.article__list {
}
.article__item {
    margin-top: 24px;
    display: flex;
    align-items: flex-start;

    &:nth-child(1) {
        margin-top: 16px;
    }
    &:hover {
        p {
            color: #2c36de;
        }
    }

    .icon {
        font-size: 14px;
        line-height: 24px;
        flex-shrink: 0;
        color: #2c36de;
        margin-right: 8px;
    }
    p {
        .maxLine(2);

        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
    }
}
// 没有更多
.noMore {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        letter-spacing: 0.07px;
        color: #999;
    }

    &::after,
    &::before {
        content: '';
        width: 40px;
        height: 1px;
        background-color: #e5e5e5;
    }
    &::after {
        margin-left: 12px;
    }
    &::before {
        margin-right: 12px;
    }
}
// ----------------------------- 列表项 End -----------------------------
