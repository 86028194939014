// ---------------------------- 置顶交易商样式
.topBrokerBox {
    height: 98px;
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none !important;
    }
}

.topBroker {
    width: 100%;
    min-width: 672px;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    overflow: hidden;
    display: flex;
    background-color: transparent;

    &.topBroker5 {
        &>li {
            width: calc((100% - 64px) / 5);
        }
    }

    &.topBroker7 {
        &>li {
            width: calc((100% - 96px) / 7);
        }
    }

    &>li {
        min-width: 155px;
        max-width: 164px;
        height: 98px;
        padding: 8px;
        margin-right: 16px;
        background-color: #FFFFFF;
        box-sizing: border-box;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px rgba(26, 35, 61, 0.1);
        position: relative;

        &:last-child {
            margin-right: 0;
        }

        &>a {
            width: 100%;
            height: 100%;
            display: block;
            position: relative;
        }
    }
}

@media screen and (max-width: 768px) {
    .topBrokerBox {
        height: auto;
    }

    .topBroker {
        padding: 24px 0 0;
    }
}

@media screen and (max-width: 415px) {

    .topBroker {
        padding: 16px 24px 0 24px;
        background-color: #FFFFFF;
        display: flex;
        grid-column-gap: 0;

        &.topBrokerBoxWidth {
            display: flex;
            grid-column-gap: 0;
        }

        &>li {
            min-width: 121.6px;
            width: 121px;
            height: 90px;
            margin-right: 16px;
        }
    }
}