.analyst {
    padding: 30px 0 60px;
    border-radius: 8px;
    background-color: var(--pc-base-background);
    position: relative;
}

.tabsBox {
    padding: 0 24px;
    display: block;
    background-color: var(--pc-base-background);
}

.analystTabs {
    box-sizing: border-box;
    background-color: var(--pc-base-background);
    position: relative;
    z-index: 1;

    &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #e5e5e5;
        position: absolute;
        bottom: 1px;
        left: 0;
    }

    :global {
        .swiper-button-prev,
        .swiper-button-next {
            top: 55%;
            background-color: var(--pc-base-background);
        }
    }
}

.analystCon {
    display: flex;
    justify-content: space-between;
    padding: 0 24px 24px;
}

.analystList {
    width: 813px;
    overflow-y: auto;
    box-sizing: border-box;
    scrollbar-width: 12px;
    scrollbar-color: #e7e7e7 #fff;

    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background: #e7e7e7;
        border-radius: 0 !important;
        box-shadow: inset 0 0 5px rgb(0 21 41 / 5%);
    }

    &::-webkit-scrollbar-track {
        background: hsla(0deg, 0%, 100%, 0.15);
        box-shadow: inset 0 0 5px #2525250d;
    }
}

.analystDetail {
    width: calc(100% - 813px);
    margin-left: 16px;
}

.botTip {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.6px;
    color: #999;
    padding-top: 24px;
    margin: 0 24px;
    border-top: 1px solid #f2f2f4;
}
