@import '~@/styles/utils.less';

.swiperCenter {
    width: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    overflow: hidden;
}

.swiper_vw {
    width: 100vw;
}

.swiperContain {
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
    user-select: none;

    :global {
        .swiper-pagination {
            bottom: 40px;
            height: 5px;
        }

        .swiper-button-disabled {
            cursor: pointer;
            opacity: 1;
            pointer-events: auto;
        }
    }

    & > div {
        height: 100%;
    }

    .swiperBtnNext,
    .swiperBtnPrev {
        top: 50%;
        width: 38px;
        height: 38px;
        object-fit: contain;
        background-color: rgba(255, 255, 255, 0.1);
        // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        opacity: 0;
        border-radius: 50%;
        transition: opacity 0.8s;

        &::after {
            color: var(--pc-base-background);
            font-weight: 400;
            font-size: 12px;
        }
    }

    .swiperBtnNext {
        right: 16px;
    }

    .swiperBtnPrev {
        left: 16px;
    }

    &:hover {
        .swiperBtnNext,
        .swiperBtnPrev {
            opacity: 1;
            background-color: #fff;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
            &::after {
                color: #171617;
            }
        }
    }
}

.swiperCon {
    width: 100%;
    overflow: hidden;
    // border-radius: 4px;
    :global {
        .swiper-pagination-horizontal {
            display: flex;
            align-items: center;
            // justify-content: flex-start;
            justify-content: center;
            padding-left: 24px;
        }
    }
}

.swiperSlideBox {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
    background: #0c0d13;
}

.img_url_box {
    width: 100%;
    height: 100%;
    max-width: 1376px;
    margin: auto;
    position: relative;
    z-index: 2;
}

.playBtn {
    width: 240px;
    height: 60px;
    background-color: #fff;
    border-radius: 4px;
    color: #262626;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    margin-top: 40px;
}

.swiperSlide {
    box-sizing: border-box;
    height: 100%;
    .swiper_slide_item {
        height: 100%;
        position: relative;
    }
    .left_mask {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 600px;
        z-index: 10;
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, #0c0d13);
    }
    .right_mask {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 600px;
        z-index: 10;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #0c0d13);
    }
    .text_con {
        position: absolute;
        z-index: 12;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        max-width: 1376px;
        padding: 0 16px;
        margin: auto;
    }
}

.swiperBullet {
    // position: relative;
    // bottom: 12px;
    display: inline-block;
    width: 75px;
    height: 4px;
    margin-right: 4px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }
}

.swiperBulletActive {
    position: relative;
    // bottom: 12px;
    overflow: hidden;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #fff;
        border-radius: 1px;
        animation: progressMove linear 5s 1 forwards;
        content: '';
    }
}

.swiperSlideTitle:hover {
    // height: 294px;
    // max-height: 294px;
    // padding-top: 0;
    // padding-bottom: 42px;
    // .swiperSlideTitleText {
    //     display: block;
    // }
}

.swiperSlideTitle {
    box-sizing: border-box;
    width: 100%;
    color: var(--pc-base-background);
    // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000);
    cursor: pointer;
    transition: max-height 0.5s;

    .swiperSlideTitleText {
        width: 447px;
        box-sizing: border-box;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 32px;
        line-height: 1.5;
        .maxLine(4);
    }

    .swiperSlideTitleOne {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 998px) {
    .swiperSlideTitle {
        min-height: 60px;
        max-height: 148px;
        font-size: var(--pc-font-size-16);

        .swiperSlideTitleText {
            min-height: 28px;
            max-height: 70px;
            // margin: 12px 0 20px;
            margin-bottom: 20px;
        }
    }
}

@keyframes progressMove {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
