.main {
    display: flex;
    flex-direction: column;
}
.list__item {
    padding: 16px 16px 0;
    background-color: var(--invalid-name);
    & > div {
        padding-bottom: 16px;
        border-bottom: 1px solid #e5e5e5;
    }
}
.list__item:nth-last-child(2) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    /* 设置样式 */
    & > div {
        border-bottom: none;
    }
}
