.listRow {
    padding: 0 10px;
    .listCol {
        width: 100%;

        & > a > div {
            border-bottom: 1px solid #e5e5e5;
        }
        &:last-child {
            & > a > div {
                border-bottom: none;
            }
        }
    }
}
