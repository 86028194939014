@import url('~@/styles/utils.less');

.hot_news {
    border-radius: 4px;
    padding: 12px;
    background-color: #fff;
    margin-top: 12px;
}

.tit {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    margin-bottom: 8px;
}

.hot_list {
    margin: 0;
    padding: 0;

    li {
        width: 100%;
    }
}

.hot_item {
    .text_item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 12px 0;
    }

    .num {
        display: inline-block;
        width: 17px;
        height: 17px;
        margin-right: 8px;
        color: #999;
        line-height: 17px;
        text-align: center;
        border-radius: 4px;
    }

    .rank {
        color: #fff;
    }

    .num1 {
        background-image: linear-gradient(to bottom, #ff9f1a, #fe250d);
    }

    .num2 {
        background-image: linear-gradient(to bottom, #ffd717, #f25700);
    }

    .num3 {
        background-image: linear-gradient(to bottom, #ffeb82, #ff8000);
    }

    .font {
        font-size: 16px;
        font-family: D-DIN, sans-serif;
    }

    .text {
        width: calc(100% - 26px);
        color: #262626;
        line-height: 17px;
        .maxLine(1);
    }
}
