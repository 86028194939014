.page {
    width: 100% !important;
    margin: 0 auto;
}

// @media screen and (max-width: 415px) {
//     .page {
//         background-color: #FFFFFF;
//     }
// }
