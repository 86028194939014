@import '~@/styles/utils.less';

.wrapper {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.item {
    padding: 16px 12px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: var(--invalid-name);
}

// ---------------------- 头部 Start ----------------------
.item_header {
    display: flex;
    gap: 8px;
    padding-bottom: 16px;
    border-bottom: var(--pc-primary-border-1);
    .avatar {
        display: flex;
        flex-shrink: 0;
        width: 44px;
        height: 44px;
        border-radius: 44px;
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;
        .name {
            .maxLine(1);
            font-size: 16px;
            line-height: 24px;
            color: var(--color-text-primary);
        }
        .intro {
            .maxLine(1);
            font-size: 12px;
            line-height: 18px;
            color: var(--color-text-secondary);
        }
    }
    .button {
        flex-shrink: 0;
        height: 26px;
        flex-grow: 0;
        display: flex;
        box-sizing: border-box;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        margin-top: 8px;
        padding: 2px 12px;
        border-radius: 4px;
        border: solid 1px #2c36de;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        color: #2c36de;
        text-wrap: nowrap;

        span {
            &:nth-child(1) {
                font-size: 12px;
                font-weight: 500;
                font-family: PingFangSC-Medium;
            }
        }
    }
    .button.focus {
        background-color: #2c36de;
        color: var(--invalid-name);
    }
}
// ---------------------- 头部 End ----------------------
.article_list {
    padding-top: 16px;
    display: flex;
    gap: 12px;
    .title {
        .maxLine(2);
        flex: 1;
        font-size: 14px;
        line-height: 22px;
        height: 44px;
        color: var(--color-text-primary);
    }
    .cover {
        flex-shrink: 0;
        position: relative;
        width: 75px;
        height: 50px;
        border-radius: 4px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
