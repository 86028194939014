.filter__wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--invalid-name);
}
// -------------------------------- 业务列表 Start --------------------------------
.business {
    flex-shrink: 0;
    background-color: var(--invalid-name);
    z-index: 100;
}
.filter__list {
    flex: 1;
    overflow-y: auto;

    /* 针对基于 Chromium 的 Edge 浏览器 */
    scrollbar-width: 6px;
    scrollbar-color: var(--color-other-scroll) transparent;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent !important;
        box-shadow: none;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        color: var(--color-other-scroll);
    }
}
// -------------------------------- 业务列表 End --------------------------------
