.articleBox {
    box-sizing: border-box;

    .articleList {
        position: relative;
    }
}

// ---------------------------- 置顶文章样式
.topListBox {
    box-sizing: border-box;
}

.topList {
    padding: 16px 24px;
    background-color: rgba(5, 232, 193, 0.05);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
    overflow: hidden;

    & > ul {
        margin: 0;
        padding: 0;

        & > li {
            font-size: var(--pc-font-size-22);
            margin-bottom: 16px;
            line-height: normal;
            white-space: pre;
            overflow: hidden;
            text-align: center;
            cursor: pointer;
            text-overflow: ellipsis;
            // color: var(--pc-primary-color);

            &:first-child {
                margin-bottom: 10px;
                font-size: var(--pc-font-size-28);

                a {
                    font-weight: 500;
                    font-family: PingFangSC-Medium;

                    &::before {
                        top: 55%;
                        width: 2px;
                        height: 24px;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            h1 {
                font-size: inherit;
                display: inline;
            }
            a {
                padding: 0 16px;
                letter-spacing: 0.5px;
                color: #262f2d;
                position: relative;
                text-decoration: none;
                &:hover {
                    color: var(--pc-primary-color);
                }
                &::before {
                    content: '';
                    width: 1px;
                    height: 20px;
                    background-color: #262f2d;
                    position: absolute;
                    top: 52%;
                    left: 0;
                    transform: translateY(-50%);
                }

                &:first-child {
                    padding-left: 0;

                    &::before {
                        display: none;
                    }
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 998px) {
    .topItem {
        & > ul {
            & > li {
                font-size: var(--pc-font-size-18);

                &:first-child {
                    font-size: var(--pc-font-size-24);
                }
            }
        }
    }
}

@media screen and (max-width: 628px) {
    .articleBoxNull {
        display: none;
    }

    .articleBox {
        padding: 0 !important;
    }
}
