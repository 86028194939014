@import url('~@/styles/utils.less');
.wrapper {
    padding: 0 8px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;

    :global {
        .ant-picker-calendar,
        .ant-picker-panel {
            background: none !important;
            border: none !important;
        }
        .ant-picker-cell-disabled::before {
            background: none !important;
        }
        .ant-picker-content {
            position: relative;
            height: auto !important;
            &::after {
                content: '';
                position: absolute;
                top: 42px;
                left: 8px;
                width: calc(100% - 16px);
                display: inline-block;
                border-bottom: 1px dashed #e5e5e5;
            }
            thead {
                height: 42px;
                position: relative;
            }
            tbody {
                margin-top: 16px;
                height: 324px;
                td {
                    width: 58px;
                    height: 54px;
                    box-sizing: border-box;
                    padding: 0;
                }
            }
        }
    }
}
// ------------------ 头部切换按钮 Start ------------------

.header__wrapper {
    padding: 48px 8px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header__time {
    font-size: 18px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.switch_btn {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow:
        0 2px 4px -1px rgba(0, 0, 0, 0.12),
        0 4px 5px 0 rgba(0, 0, 0, 0.08),
        0 1px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    margin-right: 12px;
    cursor: pointer;
    color: #262626;

    &:last-child {
        margin-right: 0;
    }

    .icon {
        font-size: 12px;
        line-height: 1;
    }

    .left {
        transform: rotateY(180deg);
    }
}
// ------------------ 头部切换按钮 End ------------------
// ------------------ 单元格 Start ------------------
.time__wrapper {
    width: 58px;
    height: 48px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.time__day {
    font-size: 16px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.time__activity {
    .maxLine(1);
    width: 100%;
    text-align: center;
    border-radius: 4px;
    background-color: #ecedf8;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #666;
}
.today {
    .time__day {
        color: #2c36de;
        background-color: #f0f3ff;
        width: 26px;
        height: 26px;
        border-radius: 26px;
        text-align: center;
    }
}
// 选中态
.time__wrapper.active {
    border-radius: 6px;
    background-color: #f0f3ff;
    .time__activity {
        background-color: #2c36de;
        color: var(--invalid-name);
    }
}
// ------------------ 单元格 End ------------------
