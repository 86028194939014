.swiperContainBox {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;

    :global {
        .ant-image-error,
        .ant-image-placeholder {
            background-color: #ebebeb;

            img {
                width: auto;
                object-fit: contain;
            }
        }
    }
}

.aside {
    position: absolute;
    top: 100%;
    transition: transform;
    z-index: 999;
    box-sizing: border-box;
    max-width: 272px;
    min-height: 718px;
    padding: 16px;
    border-radius: 4px;
    overflow: hidden;

    &.left_aside {
        left: -100%;
    }

    &.right_aside {
        right: -100%;
    }
}
.left_affix {
    left: 272px;
}
.right_affix {
    right: 272px;
}
