@import '~@/styles/utils.less';

.swiperCenter {
    width: 100vw;
    position: absolute;
    top: 0;
    box-sizing: border-box;
}

.swiperContain {
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
    user-select: none;

    :global {
        .swiper-pagination {
            bottom: 40px;
            height: 5px;
        }

        .swiper-button-disabled {
            cursor: pointer;
            opacity: 1;
            pointer-events: auto;
        }
    }

    & > div {
        height: 100%;
    }

    .swiperBtnNext,
    .swiperBtnPrev {
        top: 50%;
        width: 38px;
        height: 38px;
        object-fit: contain;
        background-color: rgba(255, 255, 255, 0.1);
        // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        opacity: 0;
        border-radius: 50%;
        transition: opacity 0.8s;

        &::after {
            color: var(--pc-base-background);
            font-weight: 400;
            font-size: 12px;
        }
    }

    .swiperBtnNext {
        right: 16px;
    }

    .swiperBtnPrev {
        left: 16px;
    }

    &:hover {
        .swiperBtnNext,
        .swiperBtnPrev {
            opacity: 1;
            background-color: #fff;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
            &::after {
                color: #171617;
            }
        }
    }
}

.swiperCon {
    width: 100%;
    height: 100%;
    overflow: hidden;

    :global {
        .swiper-pagination-horizontal {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 24px;
        }

        .swiper-slide-active {
            &::after {
                display: none;
            }
        }
    }
}

.swiperSlideBox {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
}

.swiperSlide {
    box-sizing: border-box;
    height: 100%;
    position: relative;

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        border-radius: 6px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.swiperBullet {
    display: inline-block;
    width: 75px;
    height: 4px;
    margin-right: 4px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }
}

.swiperBulletActive {
    position: relative;
    overflow: hidden;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #fff;
        border-radius: 1px;
        animation: progressMove linear 5s 1 forwards;
        content: '';
    }
}

.swiperSlideTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    height: 50%;

    color: var(--pc-base-background);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000);
    cursor: pointer;
    transition: max-height 0.5s;

    .swiperSlideTitleText {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        margin-bottom: 80px;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: 32px;
        line-height: 1.5;
        padding: 0 40px;
        .maxLine(2);
    }

    .swiperSlideTitleOne {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 998px) {
    .swiperSlideTitle {
        min-height: 60px;
        max-height: 148px;
        font-size: var(--pc-font-size-16);

        .swiperSlideTitleText {
            min-height: 28px;
            max-height: 70px;
            // margin: 12px 0 20px;
            margin-bottom: 20px;
        }

        .swiperSlideTitleOne {
            margin: 12px;
            line-height: 150%;
        }
    }
}

@keyframes progressMove {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
