@import '~@/styles/utils.less';

.preview {
    width: 100%;
    height: 100%;
    padding-top: 129px;
}
// ------------------------- 列表 Start -------------------------
.preview__list {
    width: 100%;
    height: 600px;
}
.preview__list__item {
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: #333;
    margin-top: 2px;
    cursor: pointer;
    position: relative;
    &:nth-child(1) {
        margin-top: 0;
    }
}
.preview__list__item.active {
    background-color: #3e3e3e;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        height: 100%;
        background-color: var(--invalid-name);
    }
}

.item__cover {
    position: relative;
    width: 130px;
    height: 88px;
    border-radius: 4px;
    margin-right: 12px;
    flex-shrink: 0;
    overflow: hidden;
    .item__cover__img {
        transition: all 0.5s;
    }
    &:hover {
        .item__cover__img {
            transform: scale(1.1);
        }
    }
    .cover__play {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
    }
    .cover__play__icon {
        width: 24px;
        height: 24px;
        position: relative;
    }
}
.item__title {
    .maxLine(3);

    flex: 1;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: var(--invalid-name);
    &:hover {
        text-decoration: underline;
    }
}
// ------------------------- 列表 End -------------------------

// ---------------- 滚动条 Start -----------------
.recScrollTrackY {
    background-color: #1e1e1e !important;
    width: 8px !important;
    border-radius: 0 !important;
    padding: 2px !important;
    display: none;
}
.recScrollThumbY {
    width: 8px !important;
    background-color: #333 !important;
}
.preview__list:hover {
    .recScrollTrackY {
        display: block;
    }
}
// ---------------- 滚动条 End -----------------

// ------------------------- 查看全部 Start -------------------------
.preview__more {
    margin-top: 24px;
    height: 48px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
    cursor: pointer;
    position: relative;
}

.preview__more svg {
    height: 48px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.preview__more rect {
    fill: none;
    stroke: rgba(255, 255, 255, 0.6);
    stroke-width: 2;
    stroke-dasharray: 400%, 0;
    transition: all 0.35s linear;
}
.preview__more:hover {
    color: #fff;
    rect {
        stroke: #fff;
        stroke-dasharray: 30, 300%;
        stroke-dashoffset: 88%;
        transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    }
}
// ------------------------- 查看全部 End -------------------------
