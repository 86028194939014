.seriesItem {
    position: relative;
    // display: inline-block;
    line-height: 1.5;
    font-size: var(--pc-font-size-16);
    color: var(--pc-base-f10-color);

    &.active {
        font-weight: 500;
        font-family: PingFangSC-Medium;
        color: #2c36de;
        &::before {
            position: absolute;
            content: '';
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
            width: 32px;
            height: 1px;
            background-color: #2c36de;
        }
    }
}

@media screen and (max-width: 628px) {
    .seriesItem {
        font-size: var(--pc-font-size-14);

        &.active {
            font-weight: 500;
            font-family: PingFangSC-Medium;
            position: relative;

            &::after {
                bottom: 20%;
            }
        }
    }
}
